import { waitForElem } from "../utils/waitForElem.js";

(function () {
    /* global $ */
    "use strict";

    const authSettings = $.sc.authSettings;
	var user_type = '';

	function cym(eng, cym) {
		var re = new RegExp(eng, "g");
		
		// Get all text nodes within the body element
		var textNodes = document.createTreeWalker(
		  document.body,
		  NodeFilter.SHOW_TEXT,
		  null,
		  false
		);
	  
		while (textNodes.nextNode()) {
		  var node = textNodes.currentNode;
		  // Check if the text is not part of an attribute
		  if (!node.parentElement || node.parentElement.nodeName !== "link") {
			node.nodeValue = node.nodeValue.replace(re, cym);
		  }
		}
	  }
	
	function updateCopyrightYear() {
        const currentYear = new Date().getFullYear();
        const copyrightElement = document.getElementById('copyrightYear');

        if (copyrightElement) {
            // Use the current year or fallback to 2025 if something goes wrong
            const displayYear = isNaN(currentYear) ? 2025 : currentYear;
			if ( localStorage.getItem('language') === 'cym'){
				copyrightElement.innerHTML = `<i class="far fa-copyright"></i> Cyngor Sir Powys ${displayYear}. <a href="https://www.publicpartnerships.co.uk/" target="blank" style=" text-decoration: underline; ">Wedi\'i bweru gan ASSIST PPL</a>`;

			} else {
				copyrightElement.innerHTML = `<i class="far fa-copyright"></i> Powys County Council ${displayYear}. <a href="https://www.publicpartnerships.co.uk/" target="blank" style=" text-decoration: underline; ">Powered by PPL</a>`;

			}
        }
    }

	  

	function changeHtmlFontSize(newFontSize) {
		document.documentElement.style.fontSize = newFontSize + 'px';
	}

	// Contrast for Powys PA Web Direct Provider Registration
	function checkContrastFile() {

		if (window.location.href.indexOf('/s4s/CustomPage/Index/202?programId=6f5c73cb-6dcf-4305-bdbe-b082008f7781') > -1) {
			// console.log('checkContrastFile')
			if (localStorage.getItem("contrast") === 'normal') {
				$('.form-validation-summary span').each(function() {
					this.style.setProperty('color', '#a94442', 'important');
				});

				$('.forms-mandatory').each(function() {
					this.style.setProperty('color', '#BF1722', 'important');
				});

				$('.forms-feedback div').each(function() {
					this.style.setProperty('color', '#BF1722', 'important');
				});

				$('.form-main__questions-container').each(function() {
					this.style.setProperty('background', '#F2F2F2', 'important');
				});

				$('.directProviderRegistration .form-navigation-btns__container').each(function() {
					this.style.setProperty('background', '#fff', 'important');
				});

				$('.directProviderRegistration .characters-countdown-input_label').each(function() {
					this.style.setProperty('color', '#000', 'important');
				});

				$('.pcgf-default .form-navigation-btns__container button').each(function() {
					this.style.setProperty('background', '#170c47', 'important');
					this.style.setProperty('color', '#fff', 'important');
					this.style.setProperty('border', 'none', 'important');
				});

				$('legend').each(function() {
					this.style.setProperty('color', '#333', 'important');
				});

				$('input').each(function() {
					this.style.setProperty('background', '#fff', 'important');
					this.style.setProperty('color', '#000', 'important');
					this.style.setProperty('border', '1px solid #ccc', 'important');
				});

				$('.pcgf-default .navigation-bar__item__inner-progress').each(function() {
					this.style.setProperty('background', '#fff', 'important');
				});

				$('.pcgf-default .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('border', '3px solid #c4c1b6', 'important');
					this.style.setProperty('background', '#fff', 'important');
				});

				$('.directProviderRegistration .navigation-bar__item_visited .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('background', '#f0f0f0', 'important');
				});

				$('.pcgf-default .navigation-bar__progress-line').each(function() {
					this.style.setProperty('border', '2px solid #ccc', 'important');
					this.style.setProperty('background-color', '#fff', 'important');
				});

				$('.directProviderRegistration .question-type-label p > div > p:first-child').each(function() {
					this.style.setProperty('color', '#fff', 'important');
					this.style.setProperty('background-color', '#170c47', 'important');
				});

				$('.css-1pahdxg-control').each(function() {
					this.style.setProperty('background-color', '', 'important');
				});

				$('.css-1uccc91-singleValue').each(function() {
					this.style.setProperty('color', '', 'important');
				});

				$('.css-yk16xz-control').each(function() {
					this.style.setProperty('border-color', '', 'important');
					this.style.setProperty('background-color', '', 'important');
				});

				$('.pcgf-control__react-select__menu').each(function() {
					this.style.setProperty('color', '', 'important');
					this.style.setProperty('background-color', '', 'important');
				});

				$('.top-header-container').each(function() {
					this.style.setProperty('background-color', '#F2F2F2', 'important');
				});

				$('[data-qst-id="a07e80a4-f717-476b-835e-aa99a5a1b0e5"] div p:nth-child(2)').each(function() {
					$(this).css('background-color', 'rgb(23, 12, 71);', 'important');
					$(this).css('color', '#fff;', 'important');
				});


			} else if (localStorage.getItem("contrast") === 'dark') {

				$('.form-validation-summary span').each(function() {
					this.style.setProperty('color', '#f50313', 'important');
				});

				$('.forms-mandatory').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.forms-feedback div').each(function() {
					this.style.setProperty('color', '#f50313', 'important');
				});

				$('.form-main__questions-container').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.directProviderRegistration .form-navigation-btns__container').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.directProviderRegistration .characters-countdown-input_label').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.pcgf-default .form-navigation-btns__container button').each(function() {
					this.style.setProperty('background', '#000', 'important');
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('border', '2px solid #ff0', 'important');
				});

				$('legend').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('input').each(function() {
					this.style.setProperty('background', '#000', 'important');
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('border', '2px solid #ff0', 'important');
				});

				$('.pcgf-default .navigation-bar__item__inner-progress').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.pcgf-default .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('border', '3px solid #ff0', 'important');
					this.style.setProperty('background', '#ff0', 'important');
				});

				$('.directProviderRegistration .navigation-bar__item_visited .navigation-bar__item__icon-holder i').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});

				$('.pcgf-default .navigation-bar__progress-line').each(function() {
					this.style.setProperty('border', '2px solid #ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.directProviderRegistration .question-type-label p > div > p:first-child').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.css-1pahdxg-control').each(function() {
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.css-1uccc91-singleValue').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.css-yk16xz-control').each(function() {
					this.style.setProperty('border-color', '#ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.pcgf-control__react-select__menu').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('.top-header-container').each(function() {
					this.style.setProperty('background-color', '#000', 'important');
				});

				$('[data-qst-id="a07e80a4-f717-476b-835e-aa99a5a1b0e5"] div p:nth-child(2)').each(function() {
					$(this).css('background-color', '#000;', 'important');
					$(this).css('color', '#ff0;', 'important');
				});

				$('.pcgf-control__react-select__menu')
				.mouseenter(function() {
					$(this).css({
					'color': '#000',
					'background-color': '#ff0'
					});
				})
				.mouseleave(function() {
					$(this).css({
					'color': '#ff0',
					'background-color': '#000'
					});
				});
				
			}
		}
	
		
	}

	// Contrast for Sign Up
	function checkContrastFileSignUp() {
		if ((window.location.href.indexOf('/s4s/signup/personaldetails') > -1) || (window.location.href.indexOf('s4s/signup/PersonalDetails') > -1) || (window.location.href.indexOf('s4s/SignUp/PersonalDetails') > -1)) {
			// console.log('checkContrastFile')
			if (localStorage.getItem("contrast") === 'normal') {
				
				$('.required-asterisk').each(function() {
					this.style.setProperty('color', '#BF1722', 'important');
				});



				$('.phone-number__country-code').each(function() {
					this.style.setProperty('color', '#000', 'important');
				});
			
				$('.tint4').each(function() {
					this.style.setProperty('background', '#F2F2F2', 'important');
				});



			} else if (localStorage.getItem("contrast") === 'dark') {

				$('.required-asterisk').each(function() {
					this.style.setProperty('color', '#FC1510', 'important');
				});


				$('.phone-number__country-code').each(function() {
					this.style.setProperty('color', '#ff0', 'important');
				});

				$('.tint4').each(function() {
					this.style.setProperty('background', '#000', 'important');
				});
				
			}
		}
				
	}


	function accessibilityCore(){
		// $('.navigation-bar__container').attr('aria-label','Progress bar')

		// Add h1 and remove h2
		if ($('.js-top-header').text().length === 0) {
			let getTitleText = $('.pcgf-default .form-header__title').text();
			$('.js-ppl-registration-form-container').prepend(`<div class="top-header-container"><h1 class="top-header js-top-header">${getTitleText}</h1></div>`)
			$('.pcgf-default .form-header__title').remove()	
		}

		// Hide parent if form-header__action-buttons is empty
		if ($('.form-header__action-buttons').children().length ===0 & $('.form-header__description').text().length === 0) {
			$('.form-header__action-buttons').parent().hide();
	   	}

		// Remove description if empty
		if ($('.form-header__description').text().length === 0) {
			$('.form-header__description').remove();
		}

	}

	

    var Attributes = {
        init: function () {
            this.adjustDom();
            this.bindUIActions();
            this.unload();

           // Show work as button
			if(authSettings.isSag === true && authSettings.isWorkAsMode === false){

				if ( localStorage.getItem('language') === 'cym') {  
					$('.js-pcg-access-widget').after(
						`
						<a class="pcg-contrast__link workas-btn" href="/s4s/UserManagement/Users">Gweithio fel</a>
						`
					);
				} else {
					$('.js-pcg-access-widget').after(
						`
						<a class="pcg-contrast__link workas-btn" href="/s4s/UserManagement/Users">Work as</a>
						`
					);
				}

				

			};

			// Show end work as button
			if(authSettings.isWorkAsMode === true || (authSettings.isWorkAsMode === true && authSettings.isOriginalPrincipalSag === true)){
				if ( localStorage.getItem('language') === 'cym') {  
					$('.js-pcg-access-widget').after(
						`
						<a class="pcg-contrast__link workas-btn" href="/s4s/WorkAs/EndWorkAs">Stopio gweithio fel ${authSettings.name}</a>
						`
					);
				} else {
					$('.js-pcg-access-widget').after(
						`
						<a class="pcg-contrast__link workas-btn" href="/s4s/WorkAs/EndWorkAs">Stop working as ${authSettings.name}</a>
						`
					);
				}
				

			};

            
        },

        adjustDom: function () {

			waitForElem(`#copyrightYear`).then(() => {
				updateCopyrightYear();
			});
			
			let contrastCheck = localStorage.getItem("contrast");

			if (contrastCheck === 'normal') {
				$('body').removeClass('contrast');
			} else if (contrastCheck === 'dark') {
				$('body').addClass('contrast');
			}




			let fontCheck = localStorage.getItem("font");

			if (fontCheck === 'normal') {
				changeHtmlFontSize(16); // Change this value to your desired root font size in pixels
			} else if (fontCheck === 'big') {
				changeHtmlFontSize(20); // Change this value to your desired root font size in pixels
			}
            
			
			// const language = localStorage.getItem('language');

			// Unhide language containers
			if ( localStorage.getItem('language') === 'cym'){

				// $('.header_logo a img').attr("src", "/Resources/Councils/Powys/WELSH-Care_SupportFinder-WHITE.png");

				// const cymContainer = document.querySelector('.js-cym-content');
				// if ( cymContainer !== null ) {
				// 	cymContainer.classList.remove('hide');
				// }

				// const saveAndCloseBtn = $('.bottomRibbon .blockBtns.small:contains(Save & close)');
				// if( saveAndCloseBtn.text("Save & close")){
				// 	saveAndCloseBtn.text("Cadw a cau")
				// }
				
				
				// Global translations
				// cym('Days', 'Dyddiau');
				// cym('Hours', 'Oriau');
				// cym('SignIn', 'Logio mewn');
				// cym('Decline request', 'Dirywiad');

				// cym('Save', 'Cadw');
				// cym('Save &amp; close', 'Cadw a chau');
				// cym('Close', 'Chau');
				// cym('Next', 'Nesaf');
				// cym('Cancel', 'Canslo');
				// cym('Search', 'Chwilio');
				// cym('Status', 'Statws'); 

				// cym('Creating your package is easy', 'Mae’n hawdd creu eich cais'); 
				// cym('My support needs', 'Fy anghenion cymorth '); 
				// cym('Choose providers', 'Dewiswch ddarparwyr'); 
				// cym('Select all', 'Dewis pob un'); 
				// cym('Remove all filters', 'Tynnu pob hidlydd'); 
				// cym('Search for a provider', 'Chwilio am ddarparwyr'); 
				

				// Top nav
				cym('Accessibility Statement', 'Datganiad Hygyrchedd');
			
				// Navigation bar
				// cym('Home', 'Cymorth');
				// cym('Employers', 'Gwybodaeth');
				// cym('PAs', 'Gynorthwyydd Personol / microfenter');
				cym('PA WEB', 'Hafan');
				// cym('About this PA portal', 'Am y porth PA hwn'); 
				cym('About this website', 'Ynghylch y wefan hon '); 
				cym('Log In', ' Mewngofnodi');
				cym('Register', 'Cofrestru');
				cym('Find a PA','Dod o hyd i Gynorthwy-ydd Personol')
				cym('My Dashboard','Fy Nashfwrdd')
				cym('Dashboard','Dangosfwrdd')
				cym('Hi','Helo')
				cym('Log out','Allgofnodi')
				
				cym('My Messages','Fy Negeseuon')
				
				// cym('Work as','Gweithio fel')
				// cym('Stop working as',`Stopio gweithio fel`)

				// Footer
				cym('Powered by ASSIST', 'Wedi\'i bweru gan ASSIST');   
				cym('Contact us', 'Cysylltu â ni');   
				cym('Privacy Policy', 'Polisi preifatrwydd');   
				cym('Terms & Conditions', 'Telerau ac Amodau');   
				cym('Sitemap', 'Map Safle');   

				cym('Powys County Council', 'Cyngor Sir Powys');
				cym('Powered by PPL', 'Wedi\'i bweru gan PPL');


				waitForElem(`.VIpgJd-ZVi9od-xl07Ob-lTBxed span`).then(() => {
					setTimeout(() => {
						$('.VIpgJd-ZVi9od-xl07Ob-lTBxed span:first').html('Dewis Iaith')
					}, 500);
					
				})

				


			} else {
				const engContainer = document.querySelector('.js-eng-content');
				if ( engContainer !== null ) {
					engContainer.classList.remove('hide');
				}
			}


		
			if( window.location.href.indexOf('/s4s/Auth?ReturnUrl=/s4s/CustomPage/Index') > -1 ) {

				if ( localStorage.getItem('language') === 'cym') {

					// cym(' Create your account', 'Crewch eich cyfrif'); 
					// cym('Already have an account? Login below', 'Gyda chyfrif yn barod? Mewngofnodwch isod'); 
					cym('Create your own account for free now.', 'Creuwch gyfrif am ddim nawr?'); 
					cym("Don't have an account?", 'Dim gyfrif?'); 
					cym('SignIn', 'Mewngofnodi');

					$('#signUpPromo li a').text('Cofrestrwch am gyfrif')		
					$('#logInPromo h2').text('Gyda chyfrif yn barod? Mewngofnodwch isod')			

				}
				
			}

			


		


		
			// Form styling
			if(window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=c52adab5-9a85-45f5-8a26-b04400d6c8ee') > -1 || 
            window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/Index') > -1) {

                $('body').addClass('createEvent');

				function listingTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						// cym('Create event', 'Creu digwyddiad')
						cym('Employable personal assistant','Cynorthwyydd personol cyflogadwy');
						cym('Self employed Personal assistant (sole trader)','Cynorthwyydd personol hunangyflogedig (masnachwr unigol)')
						cym('Micro Enterprise member','Aelod Menter ficro');
						cym('Micro enterprise with employees (Not CIW registered)','Menter ficro gyda gweithwyr (Heb ei gofrestru gan AGC)');
						cym('CIW registered domiciliary care agency','Asiantaeth gofal cartref cofrestredig gan AGC');
						cym('Close', 'Cau')
						cym('Submit', 'Cyflwyno')
						cym('PA Username:', 'Enw Defnyddiwr Cynorthwy-ydd Personol:')
						cym('Description:', 'Disgrifiad:')
						cym('Hourly rate:', 'Cyfradd yr awr:')
						// cym('Details', 'Manylion')
						cym('Powys PA - Availability', 'Cynorthwywyr Personol Powys – Argaeledd')
						cym('Friday', 'dydd Gwener')
						cym('Monday', 'dydd Llun')
						cym('Saturday', 'dydd Sadwrn')
						cym('School Holidays', 'Gwyliau’r Ysgol')
						cym('Sunday', 'dydd Sul')
						cym('Term Time', 'Yn Ystod y Tymor')
						cym('Thursday', 'dydd Iau')
						cym('Tuesday', 'dydd Mawrth')
						cym('Wednesday', 'dydd Mercher')
						cym('Powys PA - Client type', 'Cynorthwywyr Personol Powys - Math o gleient')
						cym('Adults', 'Oedolion')
						cym('Children', 'Plant')
						cym('Powys PA - Languages', 'Cynorthwywyr Personol Powys – Ieithoedd')
						cym('English', 'Saesneg')
						cym('Welsh', 'Cymraeg')
						cym('Powys PA - Location', 'Cynorthwywyr Personol Powys – Lleoliad')
						cym('Brecon', 'Aberhonddu')
						cym('Builth Wells and Llanwrtyd Wells', 'Llanfair ym Muallt a Llanwrtyd')
						cym('Crickhowell', 'Crucywel')
						cym('Hay on Wye and Talgarth', 'Y Gelli Gandryll a Thalgarth')
						cym('Knighton and Presteigne', 'Trefyclo a Llanandras')
						cym('Llandrindod and Rhayader', 'Llandrindod a Rhaeadr Gwy')
						cym('Llanfair Caereinion', 'Llanfair Caereinion')
						cym('Llanfyllin', 'Llanfyllin')
						cym('Llanidloes', 'Llanidloes')
						cym('Machynlleth', 'Machynlleth')
						cym('Newtown', 'Y Drenewydd')
						cym('Welshpool and Montgomery', 'Y Trallwng a Threfaldwyn')
						cym('Ystradgynlais', 'Ystradgynlais')
						cym('Powys PA Web - Hourly Rate', 'Gwe Cynorthwywyr Personol – Cyfradd yr Awr')
						cym('£10.90 - £13.89', '£10.90 - £13.89')
						cym('£13.90 - £16.89', '£13.90 - £16.89')
						cym('£16.90 - £19.89', '£16.90 - £19.89')
						cym('£19.90+', '£19.90+')
						cym('Powys PA Web - Provider type', 'Gwe Cynorthwywyr Personol Powys - Math o ddarparwr')
						// cym('CIC', 'Gofal Integredig Cymunedol')
						// cym('Registered Care Provider', 'Darparwr Gofal Cofrestredig')
						// cym('Self Employed - Micro-enterprise', 'Hunangyflogedig - Micro-fenter')
						// cym('Self Employed - Small Organisation', 'Hunangyflogedig - Sefydliad Bach')
						// cym('Self Employed - Sole Trader', 'Hunangyflogedig - Unig Fasnachwr')
						// cym('Social Firm/Social Enterprise', 'Cwmni Cymdeithasol/Menter Gymdeithasol')
						// cym('Unregistered Provider', 'Darparwr Anghofrestredig')
						
						cym('Powys PA Web - Requirements', 'Gwe Cynorthwywyr Personol Powys – Gofynion')
						cym('Basic First Aid Training', 'Hyfforddiant Cymorth Cyntaf Sylfaenol')
						cym('CIW registered', 'Cofrestrwyd gydag Arolygiaeth Gofal Cymru')
						cym('Cover arranged for A/L', `Cyflenwi wedi'i drefnu ar gyfer Gwyliau blynyddol`)
						cym('Cover arranged for sickness', `Cyflenwi wedi'i drefnu ar gyfer salwch`)
						cym('DBS provided', `DBS wedi'i ddarparu`)
						cym('Food hygiene Training', 'Hyfforddiant hylendid bwyd')
						cym('HMRC registered', 'Wedi cofrestru gyda CThEF')
						cym('Insurance included', `Yswiriant wedi'i gynnwys`)
						cym('IT literate/Digitally confident', 'Yn llythrennog mewn TG/Yn ddigidol hyderus')
						cym('Manual handling Training', 'Hyfforddiant codi a chario')
						cym('Medication Training', 'Hyfforddiant Meddyginiaeth')
						cym('Personal care Training', 'Hyfforddiant Gofal personol')
						cym('Policies/risk assessments/support plans', 'Polisïau/asesiadau risg/cynlluniau cymorth')
						cym('References available', 'Geirdaon ar gael')
						cym('Safeguarding Training', 'Hyfforddiant Diogelu')
						cym('SCW registered', 'Wedi cofrestru gyda Gofal Cymdeithasol Cymru')
						cym('Uniform provided', 'Darperir gwisg')
						cym('Willing to drive DP user car', 'Yn fodlon gyrru car y Derbynnydd Taliad Uniongyrchol')
						cym('Willing to use PA car', 'Yn fodlon defnyddio car y Cynorthwy-yddd Personol')
						cym('Upload your logo or image. Please note files must not exceed 20Kb and must be in JPEG format.', 'Lanlwytho eich logo neu ddelwedd. Noder na ddylai ffeiliau fod yn fwy na 20Kb ac mae’n rhaid iddynt fod mewn fformat JPEG.')
						// cym('Upload a profile photo', `Lanlwytho llun proffil:`)
						cym('No file chosen', `Dim ffeil wedi'i dewis`)
						// cym('Directory image (details):', `Delwedd cyfeiriadur (manylion):`)
						cym('No file chosen', ` Dim ffeil wedi'i dewis`)

						// cym('"Please enter value..."','Rhowch werth...')
					}
				}

				if ( localStorage.getItem('language') === 'cym') {
					setTimeout(function () {

						$('.isSelectedAll').each(function () {
							$(`<label for="${$(this).attr('id')}" class="isSelectedAll-label">Dewis pob un</label>`).insertAfter($(this));
						});
	
						$('.attributeChildCb').each(function () {
							$(this).addClass(`${$(this).parent().attr('id')}`)
						});
	
						$('.amendArrow').each(function () {
							$(this).parent().addClass('amendArrow-attributes');
						});
	
						$('label').each(function () {
	
							if ($(this).text() === 'Local authority name:') {
								$(this).parent().parent().parent().parent().hide();
							}
	
							if ($(this).text() === 'Category:') {
								$(this).parent().parent().parent().parent().parent().parent().hide();
							}
	
						});
	
						$('label[for=Name]').html('Enw Defnyddiwr Cynorthwy-ydd Personol:<span class="required-asterisk">*</span>');
	
						$('legend').each(function () {
	
							if ($(this).text() === 'Directory image (list):') {
								$(this).text('Lanlwytho llun proffil:')
								$(this).parent().parent().parent().parent().parent().parent().parent().append('<li style="font-weight: 600; margin-top: 2rem;">Sgroliwch i frig y ffurflen i gadw/cyflwyno.</li>')
							}     
							
							if ($(this).text() === 'Directory image (details):') {
								$(this).text('Delwedd cyfeiriadur (manylion):')
							}  
	
						})
	
						// $('#mceu_21').prev().text('Gwybodaeth Ychwanegol:')
						$('#mceu_21').prev().text('Ebost:')
						$('#mceu_21').prev().append(`<small style="display:block; padding-bottom: 2rem">Ni fydd hwn yn weladwy ar y proffil terfynol</small>`)
	
						$('h2.lhs strong').each(function () {
	
							if ($(this).text() === 'Attributes') {
								$(this).text('Manylion')
								$(this).addClass('modalDetails')
	
								$(this).append('<div id="openModalBtnDetails" class="openModalBtn" tabindex="0">'+
								'<div class="icon-circle">'+
									'<i class="fas fa-question"></i>'+
								'</div>'+
								`<h2>Beth mae'r rhain yn ei olygu</h2>`+
							'</div>')
							}
	
						});
	
						$('#openModalBtnDetails').on('click', function(){
							if ($('#modalOverlay').length>0) {
								$('#modalOverlay').css('display','flex')
								$('#modal').css('display','flex')
							} else {
								$('#maincontent').prepend('<div id="modalOverlay" class="overlay-details"></div><div id="modal" class="modal-details" tabindex="0">'+
								'<div class="modal-content" tabindex="0">'+
									'<div class="modal-head">'+
										'<div class="modal-header">'+
											'<div class="icon-circle" >'+
												'<i class="fas fa-question"></i>'+
											'</div>'+
											`<h2 tabindex="0">Beth mae'r rhain yn ei olygu</h2>`+
										'</div>'+
										'<button id="closeModalBtn" class="close" aria-label="Close" tabindex="0"><i class="fa fa-times"></i></button>'+
									'</div>'+
									
									'<p><strong tabindex="0">Lleoliad</strong></p>'+
									`<p tabindex="0">Dyma'r lleoedd y mae Cynorthwywyr Personol yn fodlon teithio iddynt i weithio</p>`+
									'<br>'+
									
									'<p><strong tabindex="0">Gofynion</strong></p>'+
									`<p tabindex="0">Mae'r rhain yn nodweddion y mae'n rhaid i'r Cynorthwywyr Personol eu meddu i gael eu hystyried</p>`+
									'<br>'+
									
									'<p><strong tabindex="0">CIW</strong></p>'+
									'<p tabindex="0">Arolygiaeth Gofal Cymru</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">A/L</strong></p>'+
									'<p tabindex="0">Gwyliau blynyddol</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">DBS</strong></p>'+
									'<p tabindex="0">Gwasanaeth Datgelu a Gwahardd</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">HMRC</strong></p>'+
									`<p tabindex="0">Cyllid a Thollau Ei Fawrhydi</p>`+
									'<br>'+
			
									'<p><strong tabindex="0">SCW</strong></p>'+
									'<p tabindex="0">Gofal Cymdeithasol Cymru</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">DP</strong></p>'+
									'<p tabindex="0">Derbynnydd Taliad Uniongyrchol</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">PA</strong></p>'+
									'<p tabindex="0">Cynorthwy-ydd Personol</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">Argaeledd sydd ei angen</strong></p>'+
									`<p tabindex="0">Dyma'r dyddiau/amseroedd y mae'n rhaid i'r Cynorthwy-ydd Personol fod ar gael</p>`+
									'<br>'+
			
									'<p><strong tabindex="0">Ieithoedd</strong></p>'+
									`<p tabindex="0">Dyma'r ieithoedd y mae'n rhaid i'r Cynorthwy-ydd Personol eu siarad</p>`+
									'<br>'+
			
									'<p><strong tabindex="0">Math o ddarparwr</strong></p>'+
									`<p tabindex="0">Dyma'r math o ddarparwr y mae'r Unigolyn Dibynnol yn chwilio amdano</p>`+
									'<br>'+
			
									'<p><strong tabindex="0">CIC</strong></p>'+
									'<p tabindex="0">Gofal Integredig Cymunedol</p>'+
													
									
									'</div>'+
								'</div>')	
							}	
							
							
							if ($('#closeModalBtn').length>0) {
								$('#closeModalBtn').on('click', function(){
									$('#modalOverlay').css('display','none')
									$('#modal').css('display','none')
								})
							}
	
							$('.modal-content').focus();
							window.scrollTo(0,0)
						})
	
	
						$('body').on('keypress', '#openModalBtnDetails', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
					
						$('body').on('keydown', function(e){
							if (e.which === 27 || e.keyCode === 27) { // Check if Esc key is pressed
								$('#modalOverlay').css('display','none');
								$('#modal').css('display','none');
							}
						});
						
						
	
						$('h2').each(function () {
	
							if ($(this).text() === 'User') {
								$(this).html(`<p style="font-size: 1rem">Clicio ar yr <i class="fa fa-plus-square" aria-hidden="true"></i> eiconau i ehangu'r holl opsiynau, dewis yr opsiynau hidlo sy'n berthnasol i chi i helpu defnyddwyr i ddod o hyd i chi ar We Cynorthwywyr Personol.</p>`)
							}
	
						});
	
						$('.repeating-schedule__label').parent().parent().parent().hide();    
	
						$('.js-is-published').addClass('publishedSection')
						$('.js-is-published').prev().addClass('publishedSection')
						$('.js-is-published').parent().css('display','flex')
						$('.js-is-published').parent().css('margin-left','10px')
						$('.js-publish-date').addClass('publishedSection')
						$('.js-publish-date').prev().addClass('publishedSection')
						$('.js-publish-date').parent().css('display','flex')
						$('.js-publish-date').parent().css('margin-left','10px')              
	
						$('.amendArrow-right').css('border', 'none').css('margin-right', '10px').html('<i class="fa fa-plus-square" aria-hidden="true" tabindex="0"></i>');
						$('.amendAttributeHead').css('margin-left', '20px');
	
	
						//Add or remove + and - icons from 'Lincs'
						$('body').on('click', '.amendArrow', function (e) {
							if ($(this).children().hasClass('fa-plus-square')) {
								$(this).children().removeClass('fa-plus-square');
								$(this).children().addClass('fa-minus-square');
							} else if ($(this).next().next().next().html() === "Dad-ddethol y cyfan") {
								e.stopPropagation();
							} else {
								$(this).children().addClass('fa-plus-square');
								$(this).children().removeClass('fa-minus-square');
							}
						});
	
						$('body').on('keypress', '.amendArrow', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
						//Select and Deselect All 
						$('body').on('click', '.isSelectedAll', function () {
							//Add - icon on 'Lincs' when select all checkbox is checked
							if ($(this).next().html() === "Dewis pob un") {
								if ($(this).prev().prev().children().hasClass('fa-plus-square')) {
									$(this).prev().prev().children().removeClass('fa-plus-square');
									$(this).prev().prev().children().addClass('fa-minus-square');
								}
							}
							//Change the text inside of 'Select all' label
							if ($(this).is(':checked')) {
								$(this).next().html("Dad-ddethol y cyfan");
							} else {
								$(this).next().html("Dewis pob un");
							}
						});
	
						$('body').on('keypress', '.isSelectedAll', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
	
						//Check and uncheck attributes
						$('body').on('click', '.attributeChildCb', function () {
							let checkboxesCounter = 0;
							let idAttribute = $(this).parent().attr('id');
							let selectAllChecboxTick = $(this).parent().parent().children()[2];
							let selectAllCheckbox = $(this).parent().parent().children()[3];
							let checkboxesNumber = document.getElementsByClassName(idAttribute);
	
							for (let i = 0; i < checkboxesNumber.length; i++) {
								if ($(checkboxesNumber[i]).is(':checked')) {
									checkboxesCounter++;
									//Check if all the attributes' checkboxes are checked
									if (checkboxesCounter === checkboxesNumber.length) {
										selectAllCheckbox.innerText = "Dad-ddethol y cyfan";
										selectAllChecboxTick.checked = true;
									} else {
										selectAllCheckbox.innerText = "Dewis pob un";
									}
								}
							}
	
						});
	
						$('body').on('keypress', '.attributeChildCb', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
						$('.js-accordion-body ul').css('display','')

						listingTranslation()
	
	
					}, 1050);
				} else {
					setTimeout(function () {

						$('.isSelectedAll').each(function () {
							$(`<label for="${$(this).attr('id')}" class="isSelectedAll-label">Select all</label>`).insertAfter($(this));
						});
	
						$('.attributeChildCb').each(function () {
							$(this).addClass(`${$(this).parent().attr('id')}`)
						});
	
						$('.amendArrow').each(function () {
							$(this).parent().addClass('amendArrow-attributes');
						});
	
						$('label').each(function () {
	
							if ($(this).text() === 'Local authority name:') {
								$(this).parent().parent().parent().parent().hide();
							}
	
							if ($(this).text() === 'Category:') {
								$(this).parent().parent().parent().parent().parent().parent().hide();
							}
	
						});
	
						$('label[for=Name]').html('PA Username:<span class="required-asterisk">*</span>');
	
						$('legend').each(function () {
	
							if ($(this).text() === 'Directory image (list):') {
								$(this).text('Upload a profile photo')
								$(this).parent().parent().parent().parent().parent().parent().parent().append('<li style="font-weight: 600; margin-top: 2rem;">Please scroll to the top of the form to save/submit.</li>')
							}                     
	
						})
	
						$('#mceu_21').prev().text('Email:')
						$('#mceu_21').prev().append(`<small style="display:block; padding-bottom: 2rem">This won't be visible on the end profile</small>`)
						// $('#mceu_21').parent().parent().hide();
						// $('#Description').val('test') 
							
						$('h2.lhs strong').each(function () {
	
							if ($(this).text() === 'Attributes') {
								$(this).text('Details')
								$(this).addClass('modalDetails')
	
								$(this).append('<div id="openModalBtnDetails" class="openModalBtn" tabindex="0">'+
								'<div class="icon-circle">'+
									'<i class="fas fa-question"></i>'+
								'</div>'+
								'<h2>What these mean</h2>'+
							'</div>')
							}
	
						});
	
						$('#openModalBtnDetails').on('click', function(){
							if ($('#modalOverlay').length>0) {
								$('#modalOverlay').css('display','flex')
								$('#modal').css('display','flex')
							} else {
								$('#maincontent').prepend('<div id="modalOverlay" class="overlay-details"></div><div id="modal" class="modal-details" tabindex="0">'+
								'<div class="modal-content" tabindex="0">'+
									'<div class="modal-head">'+
										'<div class="modal-header">'+
											'<div class="icon-circle" >'+
												'<i class="fas fa-question"></i>'+
											'</div>'+
											'<h2 tabindex="0">What these mean</h2>'+
										'</div>'+
										'<button id="closeModalBtn" class="close" aria-label="Close" tabindex="0"><i class="fa fa-times"></i></button>'+
									'</div>'+
									
									'<p><strong tabindex="0">Location</strong></p>'+
									'<p tabindex="0">These are the places PAs are willing to travel for work</p>'+
									'<br>'+
									
									'<p><strong tabindex="0">Requirements</strong></p>'+
									'<p tabindex="0">These are attributes the PA must obtain to be considered</p>'+
									'<br>'+
									
									'<p><strong tabindex="0">CIW</strong></p>'+
									'<p tabindex="0">Care Inspectorate Wales</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">A/L</strong></p>'+
									'<p tabindex="0">Annual leave</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">DBS</strong></p>'+
									'<p tabindex="0">Disclosure and Barring Service</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">HMRC</strong></p>'+
									`<p tabindex="0">His Majesty's Revenue and Customs</p>`+
									'<br>'+
			
									'<p><strong tabindex="0">SCW</strong></p>'+
									'<p tabindex="0">Social Care Wales</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">DP</strong></p>'+
									'<p tabindex="0">Direct Payment Recipient</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">PA</strong></p>'+
									'<p tabindex="0">Personal Assistant</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">Availability needed</strong></p>'+
									'<p tabindex="0">These are the days/times the PA must be available</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">Languages</strong></p>'+
									'<p tabindex="0">These are the languages the PA must speak</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">Provider type</strong></p>'+
									'<p tabindex="0">These are the type of provider the DP is looking for</p>'+
									'<br>'+
			
									'<p><strong tabindex="0">CIC</strong></p>'+
									'<p tabindex="0">Community Integrated Care</p>'+
													
									
									'</div>'+
								'</div>')	
							}	
							
							
							if ($('#closeModalBtn').length>0) {
								$('#closeModalBtn').on('click', function(){
									$('#modalOverlay').css('display','none')
									$('#modal').css('display','none')
								})
							}
	
							$('.modal-content').focus();
							window.scrollTo(0,0)
						})
	
	
						$('body').on('keypress', '#openModalBtnDetails', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
					
						$('body').on('keydown', function(e){
							if (e.which === 27 || e.keyCode === 27) { // Check if Esc key is pressed
								$('#modalOverlay').css('display','none');
								$('#modal').css('display','none');
							}
						});
						
						
	
						$('h2').each(function () {
	
							if ($(this).text() === 'User') {
								$(this).html('<p style="font-size: 1rem">Click on the <i class="fa fa-plus-square" aria-hidden="true"></i> icons to expand all options, select the filter options that are relevant to you to help users find you on PA Web.</p>')
							}
	
						});
	
						$('.repeating-schedule__label').parent().parent().parent().hide();    
	
						$('.js-is-published').addClass('publishedSection')
						$('.js-is-published').prev().addClass('publishedSection')
						$('.js-is-published').parent().css('display','flex')
						$('.js-is-published').parent().css('margin-left','10px')
						$('.js-publish-date').addClass('publishedSection')
						$('.js-publish-date').prev().addClass('publishedSection')
						$('.js-publish-date').parent().css('display','flex')
						$('.js-publish-date').parent().css('margin-left','10px')              
	
						$('.amendArrow-right').css('border', 'none').css('margin-right', '10px').html('<i class="fa fa-plus-square" aria-hidden="true" tabindex="0"></i>');
						$('.amendAttributeHead').css('margin-left', '20px');
	
	
						//Add or remove + and - icons from 'Lincs'
						$('body').on('click', '.amendArrow', function (e) {
							if ($(this).children().hasClass('fa-plus-square')) {
								$(this).children().removeClass('fa-plus-square');
								$(this).children().addClass('fa-minus-square');
							} else if ($(this).next().next().next().html() === "Deselect all") {
								e.stopPropagation();
							} else {
								$(this).children().addClass('fa-plus-square');
								$(this).children().removeClass('fa-minus-square');
							}
						});
	
						$('body').on('keypress', '.amendArrow', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
						//Select and Deselect All 
						$('body').on('click', '.isSelectedAll', function () {
							//Add - icon on 'Lincs' when select all checkbox is checked
							if ($(this).next().html() === "Select all") {
								if ($(this).prev().prev().children().hasClass('fa-plus-square')) {
									$(this).prev().prev().children().removeClass('fa-plus-square');
									$(this).prev().prev().children().addClass('fa-minus-square');
								}
							}
							//Change the text inside of 'Select all' label
							if ($(this).is(':checked')) {
								$(this).next().html("Deselect all");
							} else {
								$(this).next().html("Select all");
							}
						});
	
						$('body').on('keypress', '.isSelectedAll', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
	
						//Check and uncheck attributes
						$('body').on('click', '.attributeChildCb', function () {
							let checkboxesCounter = 0;
							let idAttribute = $(this).parent().attr('id');
							let selectAllChecboxTick = $(this).parent().parent().children()[2];
							let selectAllCheckbox = $(this).parent().parent().children()[3];
							let checkboxesNumber = document.getElementsByClassName(idAttribute);
	
							for (let i = 0; i < checkboxesNumber.length; i++) {
								if ($(checkboxesNumber[i]).is(':checked')) {
									checkboxesCounter++;
									//Check if all the attributes' checkboxes are checked
									if (checkboxesCounter === checkboxesNumber.length) {
										selectAllCheckbox.innerText = "Deselect all";
										selectAllChecboxTick.checked = true;
									} else {
										selectAllCheckbox.innerText = "Select all";
									}
								}
							}
	
						});
	
						$('body').on('keypress', '.attributeChildCb', function (e) {
							if (e.which === 13 || e.keyCode === 13) { // Check if Enter key is pressed
								$(this).trigger('click'); // Simulate a click event
							}
						});
	
						$('.js-accordion-body ul').css('display','')

	
					}, 1000);
				}
            
                

            }

			if (window.location.href.indexOf('/s4s/EventAdministrationDetails/EventDetails') > -1) {
				
				$('.js-image-upload-container').parent().parent().prepend('<li style="font-weight: bold; font-size: 16px ">Please upload your image in both fields.</li>')
			}

			
			if ((window.location.href.indexOf('/s4s/signup/personaldetails') > -1) || (window.location.href.indexOf('s4s/signup/PersonalDetails') > -1) || (window.location.href.indexOf('s4s/SignUp/PersonalDetails') > -1)) {
				$('#myLifeExcerpt').hide();

				if ('.validation-summary-errors') {
					waitForElem(`.validation-summary-errors`).then(() => {
						setTimeout(() => {
							$('.validation-summary-errors').attr('tabindex', '0').focus();
						}, 500);
					})
				}

				$('.js-next-step-button').on('click',function(){
					waitForElem(`.validation-summary-errors`).then(() => {
						setTimeout(() => {
							$('.validation-summary-errors').attr('tabindex', '0').focus();
						}, 500);
					})
				})

				waitForElem(`#PreferableContactTypeStr_Email`).then(() => {
					$('#PreferableContactTypeStr_Email').closest('fieldset').parent().next().hide();
					$('#PreferableContactTypeStr_Email').closest('fieldset').parent().hide();
					$('#PrincipalTypes_0__Id').parent().hide();
					$('#PrincipalTypes_2__Id').parent().hide();
					checkContrastFileSignUp();
				});

				waitForElem(`.js-min-length `).then(() => {
					checkContrastFileSignUp();
				});
				
			}

			if (window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/Index') > -1) {
				$('title').text('Suggest a change');

				if ( localStorage.getItem('language') === 'cym') {
					setTimeout(function () {
						$('.actionButtons').before(
							'<ul class="account intro-title-section" style="padding: 3rem 1rem 1rem">' +
	
								'<h1 tabindex="0" style="font-size: 2rem">Awgrymu newid</h1>' +
	
							'</ul>'
						)
   
						$('legend').each(function () {

						   if (($(this).text() === 'Cyfradd yr awr:') || $(this).text() === 'Hourly rate:') {
								$(this).text('Cyfradd yr awr:')
								$(this).parent().addClass('hourly-fieldset')
								$(this).parent().parent().css('padding-left','0')
								$('.js-inline-tiny-mce').addClass('hourly-input')
						   }
	
					   })
   
					   $('#IsPublished').prev().text('Ticiwch i wneud cais i’r proffil gael ei gyhoeddi ar y safle:')

					   $('label[for="PublishDate"]').text('Dyddiad cyhoeddi')
				   }, '500')
				} else {
					setTimeout(function () {
						$('.actionButtons').before(
							'<ul class="account intro-title-section" style="padding: 3rem 1rem 1rem">' +
	
								'<h1 tabindex="0" style="font-size: 2rem">Suggest a change</h1>' +
	
							'</ul>'
						)
   
						$('legend').each(function () {
						   if ($(this).text() === 'Hourly rate:') {
							   $(this).parent().addClass('hourly-fieldset')
							   $(this).parent().parent().css('padding-left','0')
							   $('.js-inline-tiny-mce').addClass('hourly-input')
						   }   
	
					   })
   
					   $('#IsPublished').prev().text('Tick to request profile published on site:')
				   }, '500')
				}
 
				
			}

			 if (window.location.href.indexOf('/s4s/MyProfile/MyProfileTab') > -1) {

				function myProfileTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Close','Cau')
						cym('My Profile','Fy Mhroffil')
						cym('Edit your profile details below','Golygu manylion eich proffil isod')
						cym('I receive funding to help with my support', `Rwy'n derbyn cyllid i helpu gyda’m cefnogaeth`)
						cym('Title', 'Teitl')
						cym('Please select', 'Dewiswch')
						cym('Rev','Y Parch')
						cym('Additional Information','Gwybodaeth Ychwanegol')
						cym('Ask the Expert Details','Manylion Gofyn i’r Arbenigwr')
						cym('Documents','Dogfennau')
						cym('Email','E-bost')
						cym('Forename','Enw cyntaf')
						cym('Surname','Cyfenw')
						cym('PA reference','Cyfeirnod Cynorthwy-ydd Personol')
						cym('Upload your logo or image. Please note files must not exceed 20Kb and must be in JPEG format.','Lanlwytho eich logo neu ddelwedd. Noder na ddylai ffeiliau fod yn fwy na 20Kb ac mae’n rhaid iddynt fod mewn fformat JPEG. Ni ddewiswyd unrhyw ffeil')
						cym('Security details','Manylion diogelwch')
						cym('Preferable contact method','Dull cysylltu sy’n well gennych')
						cym('Email','E-bost')
						cym('Phone','Ffôn')
						cym('Date of birth','Dyddiad geni')
						cym('Month','Mis')
						cym('January', 'Ionawr')
						cym('February', 'Chwefror')
						cym('March', 'Mawrth')
						cym('April', 'Ebrill')
						cym('May', 'Mai')
						cym('June', 'Mehefin')
						cym('July', 'Gorffennaf')
						cym('August', 'Awst')
						cym('September', 'Medi')
						cym('October', 'Hydref')
						cym('November', 'Tachwedd')
						cym('December', 'Rhagfyr')
						cym('Security question', 'Cwestiwn Diogelwch')
						cym('What is your mother’s first name', 'Beth yw enw cyntaf eich mam')
						cym('What is your mother’s maiden name', 'Beth yw enw morwynol eich mam')
						cym('What is your father’s first name', 'Beth yw enw cyntaf eich tad')
						cym('What was the name of your first pet', 'Beth oedd enw eich anifail anwes cyntaf')
						cym('What city or town were you born in', 'Ym mha ddinas neu dref y cawsoch eich geni')
						cym('What was your childhood nickname', 'Beth oedd llysenw eich plentyndod')
						cym('What street did you grow up on', 'Ar ba stryd cawsoch eich magu')
						cym('Where was your first job', 'Ble oedd eich swydd gyntaf')
						cym('What is the mark of your first car', 'Beth oedd gwneuthuriad eich car cyntaf')
						cym(`What is your father's middle name`, 'Beth yw enw canol eich tad')
						cym('What was the name of your childhood best friend', 'Beth oedd enw ffrind gorau eich plentyndod')
						cym('Security answer', 'Ateb cwestiwn diogelwch')
						cym('Address line:', 'Llinell cyfeiriad:')
						cym('Town:', 'Tref:')
						cym('County:', 'Sir:')
						cym('Postcode:', 'Cod post:')
						cym('Country:', 'Gwlad:')
						cym('Change my address', 'Newid fy nghyfeiriad')
						cym('Daytime telephone (Landline number):', 'Ffôn yn ystod y dydd (Rhif llinell dir):')
						cym('Mobile:', 'Ffôn Symudol:')
						cym('Fax:', 'Ffacs:')
						cym('SSN:', 'SSN:')
						cym('Save changes', 'Cadw’r newidiadau')
						cym('Position', 'Swydd')
						cym('Choose file','Dewis ffeil')
						cym('No file chosen',`Heb ddewis ffeil`)
						cym('Search address', 'Chwilio cyfeiriad')
						cym('Find address', 'Dod o hyd i’r cyfeiriad')
						cym('Add address', 'Ychwanegu cyfeiriad')
						cym('You have successfully saved your changes.',`Rydych chi wedi llwyddo i gadw eich newidiadau.`)
						cym('Cancel','Canslo')
						cym('There are no items found',`Heb ddod o hyd i unrhyw eitemau`)
						cym('Edit private data','Golygu data preifat')
					} 
				}

				setTimeout(function () {
					$('body').addClass('myProfileTab');  
					$('label[for="UserPicUploadImage"]').text('Upload your logo or image. Please note files must not exceed 20Kb and must be in JPEG format.');
					$('label[for="UserPicUploadImage"]').css({
						'display': 'block',
						'width': '100%',
						'margin-bottom': '1rem'
					});

					$('a[action="AskTheExpertDetailsTab"]').parent().hide();
					$('a[action="DocumentsTab"]').parent().hide();

					myProfileTranslation();
			   	}, '500')


				const mutationObserver = new MutationObserver(entries =>{
					myProfileTranslation();
				})

				const parent = document.body
				
				mutationObserver.observe(parent,{
					subtree: true,
					attributes: true,
				})

				if ( localStorage.getItem('language') === 'cym') {
					$('.address-search__search-string').attr('placeholder','Chwilio cyfeiriad')
				}
			 }

			 if (window.location.href.indexOf('/s4s/MyProfile/AdditionalInformationTab/') > -1) {

				function myProfileAddittionalTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Close','Cau')
						cym('My Profile','Fy Mhroffil')
						cym('Edit your profile details below','Golygu manylion eich proffil isod')
				
						cym('Additional Information','Gwybodaeth Ychwanegol')
						cym('Additional information','Gwybodaeth ychwanegol')
						cym('Ask the Expert Details','Manylion Gofyn i’r Arbenigwr')
						cym('Documents','Dogfennau')
						cym('Save changes', 'Cadw’r newidiadau')
						cym('Add address', 'Ychwanegu cyfeiriad')
						cym('You have successfully saved your changes.',`Rydych chi wedi cadw'ch newidiadau yn llwyddiannus.`)
						cym('Cancel','Canslo')
						cym('Notifications settings','Gosodiadau hysbysiadau')
						cym('I am happy to receive e-newsletters and other useful information',`Rwy'n hapus i dderbyn e-gylchlythyrau a gwybodaeth ddefnyddiol arall`)
						cym('Edit private data','Golygu data preifat')
					}
				}

				setTimeout(function () {
					$('body').addClass('myProfileTab');  
					myProfileAddittionalTranslation();
			   	}, '500')

				const mutationObserver = new MutationObserver(entries =>{
					myProfileAddittionalTranslation();
				})

				const parent = document.body
				
				mutationObserver.observe(parent,{
					subtree: true,
					attributes: true,
				})
			 }


			 if (window.location.href.indexOf('/s4s/MyProfile/AskTheExpertDetailsTab/') > -1) {

				function myProfileAskTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Close','Cau')
						cym('My Profile','Fy Mhroffil')
						cym('Additional Information','Gwybodaeth Ychwanegol')
						cym('Ask the Expert Details','Manylion Gofyn i’r Arbenigwr')
						cym('Documents','Dogfennau')
						cym('Save changes', 'Cadw’r newidiadau')
						cym('You have successfully saved your changes.',`Rydych chi wedi cadw'ch newidiadau yn llwyddiannus.`)
						cym('Cancel','Canslo')
						cym('Update your Ask the Expert information. If you want to remain anonymous, please do not use your real name as your Ask the Expert Name - make up a new one!',`Diweddaru eich gwybodaeth Gofyn i'r Arbenigwr. Os ydych chi am aros yn ddienw, peidiwch â defnyddio eich enw iawn fel eich Enw Gofyn i’r Arbenigwr - lluniwch un newydd!`)
						cym('Your Ask the Expert Name:',`Eich Enw Gofyn i'r Arbenigwr:`)
						cym('Interests:','Diddordebau:')
						cym('Go to Ask the Expert',`Mynd i Ofyn i'r Arbenigwr`)
						cym('Edit private data','Golygu data preifat')
					}	
				}

				setTimeout(function () {
					$('body').addClass('myProfileTab');  
					myProfileAskTranslation();
			   	}, '500')

				const mutationObserver = new MutationObserver(entries =>{
					myProfileAskTranslation();
				})

				const parent = document.body
				
				mutationObserver.observe(parent,{
					subtree: true,
					attributes: true,
				})
			 }

			 if (window.location.href.indexOf('/s4s/MyProfile/DocumentsTab/') > -1) {

				function myProfileDocumentsTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Close','Cau')
						cym('My Profile','Fy Mhroffil')
						cym('Additional Information','Gwybodaeth Ychwanegol')
						cym('Ask the Expert Details','Manylion Gofyn i’r Arbenigwr')
						cym('Documents','Dogfennau')
						cym('Save changes', 'Cadw’r newidiadau')
						cym('You have successfully saved your changes.',`Rydych chi wedi cadw'ch newidiadau yn llwyddiannus.`)
						cym('Cancel','Canslo')
						cym('You currently do not have any documents','Nid oes gennych unrhyw ddogfennau ar hyn o bryd')
						cym('Edit private data','Golygu data preifat')
					}	
				}

				setTimeout(function () {
					$('body').addClass('myProfileTab');  
					myProfileDocumentsTranslation();
			   	}, '500')

		
				const mutationObserver = new MutationObserver(entries =>{
					myProfileDocumentsTranslation();
				})

				const parent = document.body
				
				mutationObserver.observe(parent,{
					subtree: true,
					attributes: true,
				})
			 }


			 if (window.location.href.indexOf('/s4s/MyProfile/EditPrivateDataTab') > -1) {

				function editPrivateDataTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Please review and correct the information below','Adolygwch a chywirwch y wybodaeth isod')
						cym('You must fill in the Current password field.',`Rhaid i chi lenwi'r maes Cyfrinair Cyfredo.`)
						cym('Edit your private data below','Golygu eich data preifat isod')
						cym('New password','Cyfrinair newydd')
						cym('Confirm new password','Cadarnhau’r cyfrinair newydd')
						cym('Please confirm the changes','Cadarnhau’r newidiadau')
						cym('Current password','Cyfrinair cyfredol')
						cym('Save changes','Cadw’r newidiadau')
						cym('Your password should contain at least', 'Dylai eich cyfrinair gynnwys o leiaf')
						cym('characters long', 'nod o hyd')
						cym('1 number', '1 rhif')
						cym('Your confirmation password must match your password.',`Rhaid i'ch cyfrinair cadarnhau gyd-fynd â'ch cyfrinair.`)
						cym('Please enter a valid phone number','Rhowch rif ffôn dilys.')
						cym('Name','Enw')
						cym('Email','E-bost')
						cym('Mobile','Ffôn Symudol')
						cym('Send code','Anfon y cod')
						cym('Private data','Data preifat')
						cym('Close','Cau')
						cym('Password strength','Cryfder y cyfrinair')
						cym('Weak','Gwan')
						cym('Too weak','Rhy Wan')
						cym('Fair','Gweddol')
						cym('Good','Da')
						cym('Strong','Cryf')
						cym('uppercase character','prif gymeriad')
						cym('lowercase character','cymeriad llythrennau bach')
						cym('special character','cymeriad arbennig')
						cym('Edit private data','Golygu data preifat')

						$('label[for=Daytime]').html('Ffôn yn ystod y dydd (Rhif llinell dir):<span class="required-asterisk hide">*</span>');

					}	else {
						$('label[for=Daytime]').html('Daytime telephone (Landline number):<span class="required-asterisk hide">*</span>');
					}
				}

				setTimeout(function () {
					$('body').addClass('myProfileTab');  
					editPrivateDataTranslation();
			   	}, '500')

				const mutationObserver = new MutationObserver(entries =>{
					editPrivateDataTranslation()
				})

				const parent = document.body
				
				mutationObserver.observe(parent,{
					subtree: true,
					attributes: true,
				})

			 }


			 if ((window.location.href.indexOf('/s4s/signup/personaldetails') > -1) || (window.location.href.indexOf('s4s/signup/PersonalDetails') > -1) || (window.location.href.indexOf('s4s/SignUp/PersonalDetails') > -1)) {

				function signUpTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Password & Security details', 'Cyfrinair a Manylion diogelwch')
						cym('Please enter a valid phone number','Rhowch rif ffôn dilys')
						
						cym('Your password should contain at least', 'Dylai eich cyfrinair gynnwys o leiaf')
						cym('8 characters long', '8 nod o hyd')
						cym('1 number', '1 rhif')
						cym('Security question', 'Cwestiwn Diogelwch')
						cym('What is your mother’s first name', 'Beth yw enw cyntaf eich mam')
						cym('What is your mother’s maiden name', 'Beth yw enw morwynol eich mam')
						cym('What is your father’s first name', 'Beth yw enw cyntaf eich tad')
						cym('What was the name of your first pet', 'Beth oedd enw eich anifail anwes cyntaf')
						cym('What city or town were you born in', 'Ym mha ddinas neu dref y cawsoch eich geni')
						cym('What was your childhood nickname', 'Beth oedd llysenw eich plentyndod')
						cym('What street did you grow up on', 'Ar ba stryd cawsoch eich magu')
						cym('Where was your first job', 'Ble oedd eich swydd gyntaf')
						cym('What is the mark of your first car', 'Beth oedd gwneuthuriad eich car cyntaf')
						cym(`What is your father's middle name`, 'Beth yw enw canol eich tad')
						cym('What was the name of your childhood best friend', 'Beth oedd enw ffrind gorau eich plentyndod')
						cym('Security answer', 'Ateb cwestiwn diogelwch')
						cym('Contact details', 'Manylion cyswllt')
						cym('Daytime telephone (Landline number)', 'Ffôn yn ystod y dydd (rhif llinell dir)')
						cym('Mobile', 'Ffôn Symudol')
						cym('Address details', 'Manylion cyfeiriad')
						cym('Search address', 'Chwilio cyfeiriad')
						cym('Find address', 'Dod o hyd i’r cyfeiriad')
						cym('Add address', 'Ychwanegu cyfeiriad')
						cym('User type', 'Math o Ddefnyddiwr')
						cym('Is a staff member?', 'Ydy aelod o staff?')
						cym('Is an individual?', 'Unigolyn?')
						// cym('Is a support team?', 'Ydy tîm cefnogi?')
						
						cym('Create account', 'Creu cyfrif')

						$('#Daytime_IsRequired').prevAll('label:first').text('Ffôn yn ystod y dydd (rhif llinell dir:')

						cym('Rev','Y Parch')

						cym('Please review and correct the information below.','Adolygwch a chywirwch y wybodaeth isod.')
						cym('The Forename field is required.','Mae’n ofynnol llenwi’r maes Enw Cyntaf.')
						cym('The Surname field is required.','Mae’n ofynnol llenwi’r maes Cyfenw.')
						cym('The Email field is required.','Mae’n ofynnol cwblhau’r maes ar gyfer e-bost.')
						cym('The confirm password is required.','Mae’n ofynnol cael y cyfrinair cadarnhau.')
						cym('The Email field must contain a valid email address.',`Rhaid i’r maes ar gyfer e-bost gynnwys cyfeiriad e-bost dilyss`)
						cym('The Password field is required.','Mae’n ofynnol llenwi’r maes ar gyfer cyfrinair.')
						cym('Your confirmation password must match your password.',`Rhaid i’ch cyfrinair cadarnhau, fod yr un peth â’ch cyfrinair`)
						cym('This Email is already in use. Please provide a different Email.',`Mae’r e-bost hwn yn cael ei ddefnyddio eisoes. Darparwch e-bost gwahanol os gwelwch yn dda.`)
						cym('Password strength','Cryfder y cyfrinair')
						cym('Weak','Gwan')
						cym('Too weak','Rhy Wan')
						cym('Fair','Gweddol')
						cym('Good','Da')
						cym('Strong','Cryf')
						cym('Password', 'Cyfrinair')
						cym('Sign up steps', 'Camau cofrestru')
						cym('Personal details', 'Manylion personol')
						cym('Council name:', "Enw'r Cyngor:")
						cym('Powys County Council', 'cyngor sir Powys')
						cym('I receive funding to help with my support', `Rwy'n derbyn cyllid i helpu gyda’m cefnogaeth`)
						cym('At least 3 characters are required for your search term','Mae angen 3 llythyren o leiaf ar gyfer eich term chwilio')
						
						cym('Preferable contact method', 'Dull cyswllt gorau')
						cym('Select address','Detholwch gyfeiriad')
						cym('No results found','Heb ddod o hyd i ganlyniadau')
						cym('Loading more results',`Llwytho rhagor o ganlyniadau`)
						cym('Confirm password', 'Cadarnhau’r cyfrinair')

						cym('Please enter a House number or a House name','Rhowch Rif Tŷ neu Enw Tŷ')
						cym('The field is required','Mae’n ofynnol llenwi’r maes hwn')

						cym('Add address','Ychwanegu cyfeiriad')
						cym('Type in your details below.','Teipiwch eich manylion isod.')
						cym('Country','Gwlad')
						cym('Company name','Enw’r cwmni')
						cym('Flat name','Enw’r fflat')
						cym('House number','Rhif y tŷ')
						cym('House name','Enw’r tŷ')
						cym('Street','Stryd')
						cym('Town','Tref')
						cym('County','Sir')
						cym('Postcode','Cod Post')
						cym('Save & Close','Cadw a Chau')
						cym('Close window','Cau’r ffenestr')

						cym('Phone', 'Ffon')
						cym('Gender', 'Rhyw')
						cym('Male', 'Gwryw')
						cym('Female', 'benyw')
						cym('Address','Cyfeiriad')
						cym('Action','Gweithredu')
						cym('Title', 'Teitl')
						cym('Please select', 'Dewiswch')
						cym('Forename', 'Enw cyntaf')
						cym('Surname', 'Cyfenw')
						cym('Email', 'E-bost')
						cym('Position', 'Swydd')
						cym('Close', 'Cau')
						cym('Next', 'Nesaf')					
					}
				}

				const mutationObserver = new MutationObserver(entries =>{
					signUpTranslation()
				})

				const parent = document.body

				if ( localStorage.getItem('language') === 'cym') {
					mutationObserver.observe(parent,{
						subtree: true,
						attributes: true,
					})

				}

				if ( localStorage.getItem('language') === 'cym') {
					$('.address-search__search-string').attr('placeholder','Chwilio cyfeiriad')
				}

				setTimeout(function () {
					$('body').addClass('personalDetails');  
					signUpTranslation();
			   	}, '500')

				$('body').on('click keydown', '.address-search__find-address-btn', function(event){
					if (event.type === 'click' || (event.type === 'keydown' && event.key === 'Enter')) {
						setTimeout(() => {
							signUpTranslation();
						}, 500);
					}
				});
				
				   
				
				  	
			 }


			 if (window.location.href.indexOf('s4s/signup/RegistrationConfirmation') > -1){
				function signUpConfirmationTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						cym('Registration Confirmation','Cadarnhad Cofrestru')
						cym('Thank you for registering','Diolch am gofrestru')
						cym('We will shortly send an email/ sms to the address/phone you provided. To complete your registration you must click on the link within this email/sms. Once you have done this, you will be able to use your username and password to login.',`Cyn bo hir byddwn yn anfon e-bost/sms i'r cyfeiriad/ffôn a roddwyd gennych. I gwblhau eich cofrestriad mae’n rhaid i chi glicio ar y ddolen yn yr e-bost/sms hwn. Unwaith y byddwch wedi gwneud hyn, byddwch yn gallu defnyddio eich enw defnyddiwr a'ch cyfrinair i fewngofnodi.`)					
					}
				}

				const mutationObserver = new MutationObserver(entries =>{
					signUpConfirmationTranslation();
				})

				const parent = document.body

				if ( localStorage.getItem('language') === 'cym') {
					mutationObserver.observe(parent,{
						subtree: true,
						attributes: true,
					})

				}		


				setTimeout(function () {
					$('body').addClass('personalDetails');  
						signUpConfirmationTranslation();
						$('.loginBackground').css('background','none')
			   		}, '500')
			 }
			 
			 	


			if (window.location.href.indexOf('/s4s/EventAdministrationDetailsPublic/CreateDirectory?templateId=c52adab5-9a85-45f5-8a26-b04400d6c8ee') > -1) {
				if ( localStorage.getItem('language') === 'cym') {
					setTimeout(function () {
						$('.actionButtons').before(
							'<ul class="account intro-title-section" style="padding: 3rem 1rem 1rem">' +
	
								'<h1 tabindex="0" style="font-size: 2rem">Creu digwyddiad</h1>' +
	
							'</ul>'
						   )
	
						$('legend').each(function () {
							if (($(this).text() === 'Cyfradd yr awr:') || $(this).text() === 'Hourly rate:') {
								$(this).parent().addClass('hourly-fieldset')
								$(this).parent().parent().css('padding-left','0')
								$('.js-inline-tiny-mce').addClass('hourly-input')
								// $('.value-placeholder').attr('data-content', 'Rhowch werth...');
							}                     
						})
	
						$('#EventListImageUpload_ImageUploaded').parent().parent().parent().prepend('<li style="padding-left: 15px;color: #000;font-size: 1rem;	font-weight: 600;line-height: 1.5rem;">Lanlwytho eich logo neu ddelwedd. Noder na ddylai ffeiliau fod yn fwy na 20Kb ac mae’n rhaid iddynt fod mewn fformat JPEG.</li>')
					
						$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().addClass('mar-left1')
						$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().addClass('mar-right1')
						$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().css('padding-left','0px')
	
					}, '1000')
				} else {
					setTimeout(function () {
						$('.actionButtons').before(
							'<ul class="account intro-title-section" style="padding: 3rem 1rem 1rem">' +
	
								'<h1 tabindex="0" style="font-size: 2rem">Create event</h1>' +
	
							'</ul>'
						   )
	
						$('legend').each(function () {
							if ($(this).text() === 'Hourly rate:') {
								$(this).parent().addClass('hourly-fieldset')
								$(this).parent().parent().css('padding-left','0')
								$('.js-inline-tiny-mce').addClass('hourly-input')
							}                     
						})
	
						$('#EventListImageUpload_ImageUploaded').parent().parent().parent().prepend('<li style="padding-left: 15px;color: #000;font-size: 1rem;	font-weight: 600;line-height: 1.5rem;">Upload your logo or image. Please note files must not exceed 20Kb and must be in JPEG format.</li>')					
						$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().addClass('mar-left1')
						$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().addClass('mar-right1')
						$('.js-choose-file').parent().parent().parent().parent().parent().parent().parent().css('padding-left','0px')
	
					}, '1000')
				}
               
			}

			// AUTH Login / Sign Up //
			if (window.location.href.indexOf('/s4s/Auth?ReturnUrl=/s4s/CustomPage/Index') > -1) {
				// Add class to page
				$('body').addClass('auth-page');			
			}


			// Direct Provider Registration
			if(window.location.href.indexOf('s4s/CustomPage/Index/202?programId=6f5c73cb-6dcf-4305-bdbe-b082008f7781') > -1){

				function inputTranslation(){
					if ( localStorage.getItem('language') === 'cym') {   
						// console.log('inputTranslation')

						cym('Password should match with password confirmation',`Dylai’r cyfrinair fod yr un peth â’r cyfrinair cadarnhau`)
						cym('Field must be not less than 2 symbols',`Ni ddylai'r maes fod yn llai na 2 symbol`)
						cym('Errors have been discovered. Please review your answers to this form below and correct information.',`Cafodd camgymeriadau eu darganfod. Edrychwch dros eich atebion ar y ffurflen isod a chywiro’r wybodaeth.`)
						cym('Please provide an answer','Rhowch ateb')
						cym('What was your childhood nickname?', 'Beth oedd llysenw eich plentyndod?')
						cym(`What is your mother's maiden name?`, 'Beth yw enw morwynol eich mam?')
						cym(`What is your father's middle name?`, 'Beth yw enw canol eich tad?')
						cym('What was the name of your childhood bestfriend?', 'Beth oedd enw ffrind gorau eich plentyndod?')
						cym('What was your first pet called?', 'Beth oedd enw eich anifail anwes cyntaf?')
						cym('What street did you grow up on?', 'Ar ba stryd cawsoch eich magu?')
						cym('What was your first job?', 'Beth oedd eich swydd gyntaf?')
						cym('What was the name of the town you grew up in?', 'Beth oedd enw’r dref y cawsoch eich magu ynddi?')
						cym('What was the make of your first car?', 'Beth oedd gwneuthuriad eich car cyntaf?')
						cym('Max 30 characters.',`Uchafswm o 30 o nodau.`)
						cym('Max 20 characters.',`Uchafswm o 20 o nodau.`)


						// Last page
						cym('Thank you for registering on Powys PA Web!','Diolch am gofrestru ar We Cynorthwywyr Personol Powys! ')
						cym('Please click on the Recaptcha checkbox to complete your registration','Cliciwch ar y blwch ticio Recaptcha i gwblhau eich cofrestriad')
						cym('Please click on the link in the email to enable your account.','Cliciwch ar y ddolen yn yr e-bost i alluogi eich cyfrif.')
						cym('A confirmation email has been sent to the account you registered with.',`Mae e-bost cadarnhau wedi'i anfon i'r cyfrif y gwnaethoch gofrestru ag ef.`)
						cym('Personal information',`Gwybodaeth bersonol`)
						cym('Middle name','Enw canol')
						cym('Maiden name',`Enw morwynol`)
						cym('Date of Birth',`Dyddiad Geni`)
						cym('My account details','Manylion fy nghyfrif')
						cym('Contact details','Manylion cyswllt')
						cym('Physical Address', 'Cyfeiriad Ffisegol')
						cym('Mailing Address', 'Cyfeiriad Postio')
						cym('Address','Cyfeiriad')
						cym('Phone number','Rhif ffôn')
						cym('Mobile','Ffôn Symudol')
						cym('First name', 'Enw cyntaf')
						cym('Last name', 'Cyfenw')
						cym('Email', 'E-bost')
						// Last page ends


						cym('remaining',`yn weddill`)
						cym('The field must contain a valid email address.',`Rhaid i'r maes gynnwys cyfeiriad e-bost dilys.`)
						cym('Please provide an answer','Rhowch ateb')
						cym('Your password should contain at least', 'Dylai eich cyfrinair gynnwys o leiaf')
						cym('8 characters long', '8 nod o hyd')
						cym('1 number', '1 rhif')
						cym('1 uppercase character','1 llythyren fawr')
						cym('1 lowercase character','1 llythyren fach')
						cym('1 special character','1 nod/llythyren arbennig')
						cym('Password strength','Cryfder y cyfrinair')
						cym('Weak','Gwan')
						cym('Too weak','Rhy Wan')
						cym('Fair','Gweddol')
						cym('Good','Da')
						cym('Strong','Cryf')
						cym('Field must be not less than 6 symbols',`Rhaid i faes gynnwys o leiaf 6 symbol`)
						cym('Home or Alternate Phone', 'Ffôn Cartref neu Ffôn Arall')
						cym('Mobile Phone', 'Ffôn Symudol')
						cym('Remove','Dileu')
						cym('Select','Dewiswch')
						cym('Search', 'Chwilio')
						cym('Add', 'Ychwanegu')
						cym('Yes', 'Ydy')
						cym('No', 'Nac ydy')
						cym('Mailing Address', 'Cyfeiriad Postio')
						cym('Search for...', 'Chwilio am...')
						cym('Search', 'Chwilio')
						cym('Add', 'Ychwanegu')
						cym('No results found','Heb ddod o hyd i ganlyniadau')
					}
				}
			
				$('body').addClass('directProviderRegistration'); 

				if ( localStorage.getItem('language') === 'cym') { 
					document.title = 'Cofrestriad Darparwr Uniongyrchol';
				} else {
					document.title = 'Direct Provider Registration';
				}
				

				function directProviderRegistrationTranslation(){
					if ( localStorage.getItem('language') === 'cym') {
						// console.log('directProviderRegistrationTranslation')
						cym('I acknowledge and accept PPL’s',`Rwy'n cydnabod ac yn derbyn`)
						cym('for accessing the Service.',`PPL ar gyfer cyrchu'r Gwasanaeth.`)
		
						cym('PROVIDER DETAILS','MANYLION Y DARPARWR')
						cym('Provider Details','Manylion y Darparwr')
						cym('Account Login Details','Manylion Mewngofnodi Cyfrif')
						cym('Contact Details','Manylion Cyswllt')
						cym('TERMS OF SERVICE AGREEMENT','CYTUNDEB TELERAU GWASANAETH')
						cym('Terms of Service','Telerau Gwasanaeth')
						cym('First Name', 'Enw cyntaf')
						cym('Max 30 characters.', 'Uchafswm o 30 nod.')
						cym('Last Name', 'Cyfenw')
						cym('remaining', 'yn weddill')
						cym('Date of Birth', 'Dyddiad Geni')
						cym('Gender', 'Rhyw')
						cym('Male', 'Gwryw')
						cym('Female', 'Benyw')
						cym('Prefer not to disclose', 'Gwell gennyf beidio â datgelu')
						cym('Next', 'Nesaf')
						cym('ACCOUNT LOGIN DETAILS','MANYLION MEWNGOFNODI CYFRIF')
						cym('Powys PA Web Direct Provider Registration','Cofrestriad Darparwr Uniongyrchol Gwe Cynorthwywyr Personol Powys')
						cym('Previous','Blaenorol')
						cym('Email', 'E-bost')
						cym('Password', 'Cyfrinair')
						cym('Password confirmation', 'Cadarnhau cyfrinair')
						cym('Security Question', 'Cwestiwn Diogelwch')
						
						cym('Security Answer', 'Ateb Cwestiwn Diogelwch')
						cym('Max 20 characters. remaining 20', 'Uchafswm o 20 nod. 20 yn weddill')		

						cym('There will be times we have important information we need to share with the Provider.  Please select one or more methods of communication below.', `Bydd adegau pan fydd gennym wybodaeth bwysig y mae angen i ni ei rhannu gyda'r Darparwr. Dewiswch un neu fwy o ddulliau cyfathrebu isod.`)
	
						cym('NO PO BOX', 'DIM BLWCH PO')

						cym('Max 30 characters.',`Uchafswm o 30 o nodau.`)
						cym('remaining',`yn weddill`)
						cym('The field must contain a valid email address.',`Rhaid i'r maes gynnwys cyfeiriad e-bost dilys.`)
						cym('Please provide an answer','Rhowch ateb')
						cym('Your password should contain at least', 'Dylai eich cyfrinair gynnwys o leiaf')
						cym('8 characters long', '8 nod o hyd')
						cym('1 number', '1 rhif')
						cym('1 uppercase character','1 llythyren fawr')
						cym('1 lowercase character','1 llythyren fach')
						cym('1 special character','1 nod/llythyren arbennig')
						cym('Password strength','Cryfder y cyfrinair')
						cym('Weak','Gwan')
						cym('Too weak','Rhy Wan')
						cym('Fair','Gweddol')
						cym('Good','Da')
						cym('Strong','Cryf')
						cym('Field must be not less than 6 symbols',`Rhaid i faes gynnwys o leiaf 6 symbol`)
						cym('Is the mailing address different than the physical address?', `Ydy'r cyfeiriad post yn wahanol i'r cyfeiriad ffisegol?`)
						cym('Physical Address', 'Cyfeiriad Ffisegol')


						$('[data-qst-id="a07e80a4-f717-476b-835e-aa99a5a1b0e5"]').html(`<p><div class=""><style>
						ol.a {list-style-type: decimal; padding-left:15px;}
						ol.b {list-style-type: lower-roman; padding-left:45px;}
						ol.c {list-style-type: upper-alpha; padding-left:30px;}
						</style>
						<p style="font-size: 20px;text-transform: uppercase;background-color: rgb(23, 12, 71);font-weight: bold;	color: rgb(255, 255, 255);border-radius: 5px;	padding: 7px 20px;">CYTUNDEB TELERAU GWASANAETH</p>
						<p>Ymrwymir i'r CYTUNDEB TELERAU GWASANAETH hwn (y “Cytundeb hwn”) gan a rhwng Public Partnerships LLC, cwmni atebolrwydd cyfyngedig Delaware” (“PPL”), ac unrhyw unigolyn sy'n cydsynio i'r Cytundeb hwn a'r endid y mae'r cyfryw unigolyn yn ei gynrychioli (gyda'i gilydd , “Defnyddiwr”). Cyfeirir at bob un o’r PPL a’r Defnyddiwr fel “Parti” a chyfeirir atynt gyda’i gilydd fel y “Partïon.”</p>
						<p>WRTH FEWNGOFNODI, CYRCHU, GWELD, LAWRLWYTHO, NEU DDEFNYDDIO UNRHYW RAN O RWYDWAITH, SYSTEMAU NEU ADNODDAU PPL (y “GWASANAETH”), MAE'R DEFNYDDIWR YN CYTUNO YN FFURFIOL AC YN CANIATÁU I GAEL EI RWYMO GAN HOLL DELERAU'R CYTUNDEB HWN. OS NAD YW'R DEFNYDDWR YN CYTUNO I HOLL TELERAU'R CYTUNDEB HWN, BYDD Y DEFNYDDIWR YN NODI NAD YW’N DERBYN, A BYDD PPL, OS YN BERTHNASOL, YN DAD-WEITHREDU CYFRIF Y DEFNYDDWR YN SYTH, AC NI FYDD Y DEFNYDDIWR YN CYRCHU NAC YN DEFNYDDIO UNRHYW RAN O'R GWASANAETH HWN..</p>
						<ol class="a">
						<li>
						<p>Telerau a Chyfyngiadau’r Gwasanaeth</p>
						</li>
						<ol class="c">
						<li>
						<p>Disgrifiad. Mae rhwydwaith, systemau ac adnoddau PPL (y “Gwasanaeth”) yn berchnogol i PPL ac yn cael ei warchod gan gyfreithiau a chytundebau eiddo deallusol. Mae mynediad defnyddiwr i'r Gwasanaeth wedi'i drwyddedu ac nid yw'n cael ei werthu. Yn amodol ar dderbyn y Cytundeb hwn, mae PPL yn cytuno i ddarparu cyfrif personol, anhrosglwyddadwy ac anghyfyngedig i Ddefnyddiwr i alluogi Defnyddiwr i gael mynediad i'r Gwasanaeth a'i ddefnyddio.</p>
						</li>
						<li>
						<p>Cyfyngiadau. Nid oes hawl gan y defnyddiwr:</p>
						</li>
						<ol class="b">
						<li>
						<p>Werthu, marchnata, rhentu, is-drwyddedu, neu drwyddedu unrhyw agwedd o’r Gwasanaeth neu eiddo deallusol PPL neu fel arall ddefnyddio'r Gwasanaeth at unrhyw bwrpas heblaw'r hyn a ddarperir yn benodol yn y Cytundeb hwn;</p>
						</li>
						<li>
						<p>Dehongli, dadgrynhoi, dadosod, gwrthosod, addasu, cyfieithu, gwrthbeiriannu, neu fel arall geisio cael cod ffynhonnell, algorithmau, tagiau, manylebau, pensaernïaeth, strwythur, neu elfennau eraill o'r Gwasanaeth yn gyfan gwbl neu'n rhannol, at bwrpasau cystadleuol, neu fel arall;</p>
						</li>
						<li>
						<p>Caniatáu mynediad i, darparu, datgelu neu wneud y Gwasanaeth ar gael i unrhyw unigolyn heblaw'r rhai sydd â thrwyddedau i gael mynediad i'r Gwasanaeth;</p>
						</li>
						<li>
						<p>Ysgrifennu neu ddatblygu unrhyw weithiau deilliadol yn seiliedig ar y Gwasanaeth;</p>
						</li>
						<li>
						<p>Newid, addasu, cyfieithu, neu wneud unrhyw newidiadau fel arall i'r Gwasanaeth neu unrhyw ran ohono heb ganiatâd ysgrifenedig PPL ymlaen llaw;</p>
						</li>
						<li>
						<p>Defnyddio'r Gwasanaeth i ddarparu gwasanaethau prosesu i drydydd parti, neu ddefnyddio'r un peth fel arall ar sail “canolfan gwasanaeth”;</p>
						</li>
						<li>
						<p>Datgelu neu gyhoeddi, heb ganiatâd ysgrifenedig PPL ymlaen llaw, ystadegau perfformiad neu gapasiti neu ganlyniadau unrhyw brawf meincnod a gynhaliwyd ar y Gwasanaeth;</p>
						</li>
						<li>Dileu o'r Gwasanaeth ddull adnabod, patent, hawlfraint, nod masnach, neu hysbysiadau eraill neu osgoi neu analluogi unrhyw ddyfeisiau diogelwch, ymarferoldeb, neu nodweddion; neu</li>
						<li>
						<p>Osgoi neu geisio osgoi unrhyw nodwedd ddiogelwch o'r Gwasanaeth, gan gynnwys unrhyw ymgais i fynd y tu hwnt i fynediad awdurdodedig y Defnyddiwr ac unrhyw ymgais i ryng-gipio neu fonitro unrhyw system PPL;</p>
						</li>
						<li>
						<p>Defnyddio’r Gwasanaeth mewn unrhyw fodd sy'n torri cyfraith, rheol neu reoliad cymwys.</p>
						</li>
						</ol>
						<li>
						<p>Nid yw defnyddiwr yn cael unrhyw hawliau perchnogaeth nac unrhyw hawliau eraill yn eiddo deallusol PPL na'r Gwasanaeth, ac eithrio'r rhai a nodir yn y Cytundeb hwn. Mae’r defnyddiwr yn rhoi trwydded gyfyngedig i PPL ddefnyddio data trafodion a pherfformiad y Defnyddiwr sy'n ymwneud â defnydd y Defnyddiwr o'r Gwasanaeth (e.e., gwybodaeth ystadegol am nifer yr hawliadau a broseswyd) ar sail agregedig a dad-adnabyddedig yn unig fel rhan o farchnata mewnol, datblygiad busnes, a phwrpasau dadansoddol PPL.</p>
						</li>
						<li>
						<p>Hygyrchedd. Mae'r defnyddiwr yn cytuno y gall y Gwasanaeth fod yn anhygyrch neu'n anweithredol o bryd i'w gilydd am unrhyw reswm a heb rybudd, gan gynnwys, heb gyfyngiad:</p>
						</li>
						<ol class="b">
						<li>
						<p>Diffygion offer;</p>
						</li>
						<li>
						<p>Gweithdrefnau cynnal a chadw cyfnodol neu atgyweiriadau y gall PPL eu gwneud o bryd i'w gilydd; neu</p>
						</li>
						<li>Achosion y tu hwnt i reolaeth PPL neu na ellir eu rhagweld yn rhesymol gan PPL.</li>
						</ol>
						</ol>
						</ol></div></p>`)

						$('[data-qst-id="d4e2cd15-fbc9-4b9e-b781-6b80aa2d434d"]').html(`<p><div class=""><style>
						ol.a {list-style-type: decimal; padding-left:15px;}
						ol.b {list-style-type: lower-roman; padding-left:45px;}
						ol.c {list-style-type: upper-alpha; padding-left:30px;}
						</style>
						<ol style="list-style-type: upper-alpha; padding-left: 45px;" start="5">
						<li>Offer a Data. Bydd y Defnyddiwr yn llwyr gyfrifol am ddarparu, cynnal a chadw, a sicrhau cydnawsedd â'r Gwasanaeth, yr holl ofynion caledwedd, meddalwedd, trydanol a ffisegol eraill ar gyfer defnydd Defnyddiwr o'r Gwasanaeth, gan gynnwys, heb gyfyngiad, telathrebu chysylltiadau a dolenni mynediad rhyngrwyd, porwyr gwe, neu offer, rhaglenni a gwasanaethau eraill sydd eu hangen i gyrchu a defnyddio’r Gwasanaeth. Bydd y Defnyddiwr yn llwyr gyfrifol am gywirdeb, ansawdd, uniondeb, cyfreithlondeb, dibynadwyedd, priodoldeb, a pherchnogaeth unrhyw ddata a'r holl ddata y mae Defnyddiwr yn ei gyflwyno i'r Gwasanaeth, ac eithrio bod gan PPL yr hawl i gyrchu, defnyddio a datgelu gwybodaeth bersonol am Ddefnyddiwr, am gyfrif Defnyddiwr, a'r holl ddata a ffeiliau y mae Defnyddiwr yn eu cyflwyno i'r Gwasanaeth yn unol ag Adran 2(B) isod.</li>
						</ol>
						<ol class="a" start="2">
						<li>
						<p>Preifatrwydd a Diogelwch</p>
						</li>
						<ol class="c">
						<li>
						<p>Diogelwch. Bydd y Defnyddiwr yn llwyr gyfrifol am ddiogelwch, cyfrinachedd a chywirdeb yr holl negeseuon, cynnwys, a gwybodaeth arall y mae'r Defnyddiwr yn ei derbyn, ei throsglwyddo trwy, neu ei storio ar y Gwasanaeth. Bydd y Defnyddiwr yn llwyr gyfrifol am unrhyw fynediad awdurdodedig neu anawdurdodedig i gyfrif Defnyddiwr gan unrhyw unigolyn. Mae'r Defnyddiwr yn cytuno i ysgwyddo'r holl gyfrifoldeb am gyfrinachedd cyfrinair y Defnyddiwr a'r holl ddefnydd neu gostau (os yw'n berthnasol) a geir gan ddefnyddiwr y Gwasanaeth gyda chyfrinair y Defnyddiwr. Ni fydd PPL yn atebol am unrhyw golled neu ddifrod sy'n deillio o fethiant y Defnyddiwr i gydymffurfio â'r gofynion hyn.</p>
						</li>
						<li>
						<p>Preifatrwydd. Pan fo'n rhesymol ymarferol, bydd PPL yn ceisio parchu preifatrwydd y Defnyddiwr. Ni fydd PPL yn monitro, golygu, nac yn datgelu unrhyw wybodaeth bersonol am gyfrif Defnyddiwr neu Ddefnyddiwr, gan gynnwys ei gynnwys neu ddefnydd Defnyddiwr o'r Gwasanaeth, heb ganiatâd y Defnyddiwr ymlaen llaw oni bai bod PPL yn credu'n ddidwyll bod angen gweithredu o'r fath i:</p>
						</li>
						<ol class="b">
						<li>
						<p>Gydymffurfio â phroses gyfreithiol neu ofynion cyfreithiol eraill unrhyw awdurdod llywodraethol;</p>
						</li>
						<li>
						<p>Diogelu ac amddiffyn hawliau neu eiddo PPL;</p>
						</li>
						<li>
						<p>Gorfodi'r Cytundeb hwn;/p>
						</li>
						<li>
						<p>Diogelu buddiannau defnyddwyr y Gwasanaeth heblaw'r Defnyddiwr neu unrhyw unigolyn arall; neu</p>
						</li>
						<li>
						<p>Weithredu neu gynnal a chadw a thrwsio gwasanaethau neu offer PPL, gan gynnwys y Gwasanaeth fel yr awdurdodir gan y gyfraith.</p>
						</li>
						</ol>
						<ol style="list-style-type: none; padding-left: 45px;">
						<li>
						<p>Mae PPL yn cadw'r hawl i gadw cofnod o gyfeiriad IP Defnyddiwr a'r gweithgareddau a gyflawnir gan y Defnyddiwr wrth ddefnyddio'r Gwasanaeth (gyda'i gilydd, “Data Defnydd”). Mae PPL yn cadw'r hawl i ddarparu Data Defnydd cyfanredol i drydydd personau, gan gynnwys heb gyfyngiad, yn gydnabyddiaeth am iawndal.</p>
						</li>
						</ol>
						<li>
						<p>Cyfrinachedd Gwybodaeth O Fewn y Gwasanaeth: Mae’r Defnyddiwr yn deall bod gwybodaeth a gyrchir o'r tu mewn i'r Gwasanaeth yn cael ei hamddiffyn gan gyfreithiau preifatrwydd a diogelwch ffederal a gwladwriaethol, gan gynnwys Deddf Cludadwyedd ac Atebolrwydd Yswiriant Iechyd 1996 (“HIPAA”), y Ddeddf Hawliau Addysgol a Phreifatrwydd Ffederal (“FERPA”) a chyfraith ffederal, gwladwriaethol a lleol arall. Mae'r Defnyddiwr yn deall y gall torri'r deddfau hyn arwain at gosbau troseddol yn ogystal â chosbau sifil. Mae’r Defnyddiwr yn gwarantu y bydd yn defnyddio ac yn datgelu gwybodaeth o fewn y System yn unol â'r cyfreithiau hyn yn unig yn ogystal â'r polisïau a'r gweithdrefnau a sefydlwyd gan gyflogwr y Defnyddiwr a/neu gan PPL.</p>
						</li>
						</ol>
						<li>
						<p>Cynrychiolaeth Defnyddwyr. Mae’r Defnyddiwr yn cynrychioli ac yn gwarantu i PPL:</p>
						</li>
						<ol class="c">
						<li>
						<p>I'r graddau y mae unigolyn, y Defnyddiwr (i) dros ddeunaw oed (18) ac; (ii) yn gysylltiedig, boed fel cyflogwr, contractwr, is-gontractwr, neu asiant, ag endid sy'n rhan o gontract gyda PPL.</p>
						</li>
						<li>
						<p>Mae gan y Defnyddiwr y pŵer a'r awdurdod i ymrwymo i rwymedigaethau’r Defnyddiwr o dan y Cytundeb hwn a'u cyflawni;/p>
						</li>
						<li>
						<p>Mae'r holl wybodaeth a ddarperir gan y Defnyddiwr i PPL yn wir, yn gywir ac yn gyflawn. Mae hyn yn cynnwys gwybodaeth a ddarparwyd gan y Defnyddiwr wrth sefydlu cyfrif Defnyddiwr ac unrhyw a phob newid a wneir i wybodaeth a gynhwysir yn y Gwasanaeth;</p>
						</li>
						<li>Bydd y Defnyddiwr yn cydymffurfio â holl delerau ac amodau'r Cytundeb hwn, gan gynnwys, heb gyfyngiad, y darpariaethau a nodir yn Adran 5; ac</li>
						<li>
						<p>Mae'r Defnyddiwr wedi darparu a bydd yn darparu gwybodaeth gofrestru gywir a chyflawn, gan gynnwys, heb gyfyngiad, enw cyfreithiol y Defnyddiwr, cysylltiad endid, cyfeiriad, cyfeiriad e-bost, a rhif ffôn.</p>
						</li>
						</ol>
						</ol></div></p>`)

						$('[data-qst-id="cf56f15f-76f7-4477-833f-c0648b00bd68"]').html(`<p><div class=""><style>
						ol.a {list-style-type: decimal; padding-left:15px;}
						ol.b {list-style-type: lower-roman; padding-left:45px;}
						ol.c {list-style-type: upper-alpha; padding-left:30px;}
						</style>
						<ol style="list-style-type: none; padding-left: 30px;">
						<li>
						<p>Mae torri unrhyw un o'r sylwadau hyn yn achos i PPL atal a/neu derfynu mynediad y Defnyddiwr i'r Gwasanaeth ar unwaith a heb rybudd.</p>
						</li>
						</ol>
						<ol class="a" start="4">
						<li>
						<p>Defnydd Derbyniol. Y Defnyddiwr sy'n llwyr gyfrifol am unrhyw a phob gweithred ac hepgoriad sy'n digwydd o dan gyfrif neu gyfrinair y Defnyddiwr ac mae'r Defnyddiwr yn cytuno i beidio â chymryd rhan mewn defnydd annerbyniol o'r Gwasanaethau, sy'n cynnwys, heb gyfyngiad, defnyddio'r Gwasanaeth i:</p>
						</li>
						<ol class="c">
						<li>
						<p>Dorri unrhyw gyfraith neu reoliad, neu i hwyluso torri unrhyw gyfraith neu reoliad;</p>
						</li>
						<li>
						<p>Lledaenu, storio, neu drosglwyddo negeseuon digymell, llythyrau cadwyn, neu e-bost masnachol digymell;</p>
						</li>
						<li>Lledaenu neu drosglwyddo deunydd i unigolyn rhesymol a all fod yn sarhaus, anweddus, pornograffig, difenwol, aflonyddus, sarhaus iawn, di-chwaeth, bygythiol, neu sarhaus.</li>
						<li>
						<p>Lledaenu, storio, neu drosglwyddo ffeiliau, graffeg, meddalwedd, neu ddeunydd arall sydd mewn gwirionedd neu o bosibl yn torri hawlfraint, nod masnach, patent, cyfrinach fasnach, neu hawl eiddo deallusol arall unrhyw unigolyn;</p>
						</li>
						<li>
						<p>Creu hunaniaeth ffug neu fel arall geisio camarwain unrhyw unigolyn ynghylch hunaniaeth neu darddiad unrhyw gyfathrebu;</p>
						</li>
						<li>
						<p>Allgludo, ail-allgludo, neu ganiatáu lawrlwytho unrhyw neges neu gynnwys yn groes i unrhyw gyfraith allgludo neu fewngludo, rheoliad, neu gyfyngiad yr Unol Daleithiau a'i asiantaethau neu awdurdodau, neu heb yr holl gymeradwyaethau, trwyddedau neu eithriadau gofynnol;</p>
						</li>
						<li>Ymyrryd, tarfu, neu geisio cael mynediad heb awdurdod i gyfrifon eraill ar y Gwasanaeth neu unrhyw rwydwaith cyfrifiadurol arall;</li>
						<li>
						<p>Lledaenu, storio, neu drosglwyddo firysau, ceffylau Pen Troea, neu unrhyw god neu raglen faleisus arall; neu/p>
						</li>
						<li>
						<p>Ddifrodi, analluogi, gorlwytho neu amharu ar unrhyw weinydd PPL neu'r rhwydweithiau sy'n gysylltiedig ag unrhyw weinydd PPL, neu geisio gwneud hynny;</p>
						</li>
						<li>
						<p>Ymyrryd neu geisio ymyrryd â defnydd unrhyw ddefnyddiwr arall o'r Gwasanaeth;</p>
						</li>
						<li>
						<p>Cymryd rhan mewn unrhyw weithgaredd arall y mae PPL yn ei ystyried fel gwrthdaro ag ysbryd neu fwriad y Cytundeb hwn.</p>
						</li>
						</ol>
						<ol style="list-style-type: none; padding-left: 30px;">
						<li>
						<p>Mae torri unrhyw un o'r darpariaethau hyn yn achosi i PPL atal a/neu derfynu mynediad Defnyddiwr i'r Gwasanaeth ar unwaith a heb rybudd.</p>
						</li>
						</ol>
						<li>
						<p>Terfynu. Mae'r Cytundeb hwn yn effeithiol pan fydd y Defnyddiwr yn ei dderbyn fel y nodir yma a bydd yn parhau mewn grym llawn hyd nes y bydd yn cael ei derfynu. Gall defnyddiwr derfynu'r Cytundeb hwn am unrhyw reswm ar ôl rhybudd o ddeng niwrnod ar hugain (30) ymlaen llaw i PPL. Mae PPL yn cadw’r hawl, yn ei gyfarwyddyd unigryw a heb rybudd ymlaen llaw, ar unrhyw adeg ac am unrhyw reswm i:</p>
						</li>
						<ol class="c">
						<li>
						<p>Ddileu neu analluogi mynediad i'r cyfan neu unrhyw ran o'r Gwasanaeth;;</p>
						</li>
						<li>
						<p>Atal mynediad Defnyddiwr at neu ddefnydd o'r cyfan neu unrhyw ran o'r Gwasanaeth; a</p>
						</li>
						<li>
						<p>Therfynu'r Cytundeb hwn, pryd y gall PPL, yn ôl ei ddisgresiwn llwyr, ddileu'r holl ddata sy'n ymwneud â defnydd y Defnyddiwr o'r Gwasanaeth, gan gynnwys data personol y Defnyddiwr a'r holl ddata neu ffeiliau a lanlwythwyd neu a gyflwynwyd i'r Gwasanaeth.</p>
						</li>
						</ol>
						<li>
						<p>Ymwadiad Gwarantau. MAE'R GWASANAETH YN CAEL EI DDARPARU “FEL Y MAE” HEB WARANT O UNRHYW FATH, YN DDATGANEDIG NEU’N OBLYGEDIG. MAE DEFNYDDIO'R GWASANAETH AR RISG LWYR Y DEFNYDDIWR. NID YW PPL YN GWARANTU Y BYDD Y GWASANAETH YN DDI-DOR NEU'N RHYDD O WALLAU, AC NID YW PPL YN GWNEUD UNRHYW WARANT O RAN UNRHYW GANLYNIADAU Y GELLIR EU CAEL TRWY DDEFNYDDIO'R GWASANAETH. MAE’R DEFNYDDIWR YN CYDNABOD NAD YW PPL YN RHEOLI TROSGLWYDDO DATA DROS GYFLEUSTERAU CYFATHREBU, GAN GYNNWYS, OND HEB FOD YN GYFYNGEDIG, I'R RHYNGRWYD, AC Y GALLAI GWASANAETHAU FOD YN AMODOL AR GYFYNGIADAU, OEDI, A PHROBLEMAU ERAILL SY'N GYNHENID I DDEFNYDDIO CYFLEUSTERAU CYFATHREBU O'R FATH. NID YW PPL YN GWNEUD GWARANTAU ERAILL, YN DDATGANEDIG NEU'N OBLYGEDIG, GAN GYNNWYS, HEB GYFYNGIAD, UNRHYW WARANTAU GOBLYGEDIG O FARCHNADWYEDD, ADDASRWYDD AT BWRPAS ARBENNIG, NEU WARANTAU GOBLYGEDIG SY'N CODI O GYFNOD O DDELIO NEU GYFNOD O BERFFORMIAD MEWN PERTHYNAS Â’R GWASANAETH.</p>
						</li>
						</ol></div></p>`)

						$('[data-qst-id="0378621d-489d-4f26-beac-b2f1eee3fbef"]').html(`<p><div class=""><style>
						ol.a {list-style-type: decimal; padding-left:15px;}
						ol.b {list-style-type: lower-roman; padding-left:45px;}
						ol.c {list-style-type: upper-alpha; padding-left:30px;}
						</style>
						<ol style="list-style-type: decimal; padding-left: 15px;" start="7">
						<li>Cyfyngiad Atebolrwydd. NI FYDD PPL O DAN UNRHYW AMGYLCHIADAU YN ATEBOL I’R DEFNYDDIWR NEU UNRHYW UNIGOLYN ARALL AM UNRHYW IAWNDAL ANUNIONGYRCHOL, ACHLYSUROL, CANLYNIADOL, ARBENNIG, NEU GOSBOL O UNRHYW FATH, GAN GYNNWYS ELW A GOLLWYD, BETH BNNAG Y BO’R MATH O WEITHREDU, P'UN AI MEWN CONTRACT, Y GYFRAITH GAMWEDDAU (GAN GYNNWYS ESGEULSTOD) ATEBOLRWYDD LLYM, NEU FEL ARALL, HYD YN OED OS YW PPL WEDI'I GYNGHORI YNGHYLCH POSIBILRWYDD IAWNDAL O'R FATH, AM UNRHYW FATER SY'N CODI O NEU'N YMWNEUD Â’R CYTUNDEB HWN, Y GWASANAETH NEU'R RHYNGRWYD YN GYFFREDINOL, GAN GYNNWYS, HEB GYFYNGIAD, DDEFNYDD NEU ANALLUOGRWYDD Y DEFNYDDIWR I DDEFNYDDIO’R GWASANAETH HWN, UNRHYW NEWIDIADAU I NEU FETHU CYRCHU’R GWASANAETH, OEDI, METHIANT, MYNEDIAD HEB GANIATÂD I NEU NEWID UNRHYW DROSGLWYDDIAD NEU DDATA, UNRHYW DDEUNYDD NEU DDATA A ANFONWYD NEU A DDERBYNIWYD NEU HEB EU HANFON NEU EU DERBYN, UNRHYW DRAFODIAD NEU GYTUNDEB YR YMGYMERWYD Â NHW DRWY’R GWASANAETH, NEU UNRHYW DDATA NEU DDEUNYDD GAN DRYDYDD UNIGOLYN A GYRCHWYD TRWY'R GWASANAETH, P'UN AI Y BO ATEBOLRWYDD O'R FATH YN CAEL EI HAWLIO AR SAIL CONTRACT, Y GYFRAITH CAMWEDDAU, NEU FEL ARALL. NI FYDD CYFANSWM ATEBOLRWYDD PPL AR GYFER IAWNDAL YN FWY NA $5,000. MAE RHAI GWLADWRIAETHAU YN GWAHARDD GWAHARDDIAD NEU GYFYNGIAD AR IAWNDAL ACHLYSUROL NEU GANLYNIADOL, FELLY EFALLAI NAD YW'R CYFYNGIAD RHWYMEDIGAETH HWN YN BERTHNASOL I’R DEFNYDDIWR I'R GRADDAU A WAHERDDIR NEU A GYFYNGIR GAN GYFRAITH BERTHNASOL. OS YW'R DEFNYDDWR YN ANFODLON Â'R GWASANAETH, YR UNIG RWYMEDI CYFYNGOL I’R DEFNYDDIWR FYDD I’R DEFNYDDIWR BEIDIO Â PHARHAU Â DEFNYDDIO’R GWASANAETH A THERFYNU'R CYTUNDEB HWN YN UNOL AG ADRAN 6.</li>
						<li>
						<p>Indemniad. Mae’r Defnyddiwr yn cytuno i indemnio, ystyried yn ddiniwed, ac amddiffyn PPL, ei gyfranddalwyr, cyfarwyddwyr, swyddogion, cyflogeion, ac asiantau rhag ac yn erbyn unrhyw gamau, achos, hawliad, difrod, dyled, galw, neu atebolrwydd, gan gynnwys costau rhesymol a ffioedd atwrnai, a honnir gan unrhyw unigolyn, sy’n codi o neu’n ymwneud â:</p>
						</li>
						<ol class="c">
						<li>
						<p>Y Cytundeb hwn;</p>
						</li>
						<li>
						<p>Defnydd y Defnyddiwr o'r Gwasanaeth, gan gynnwys unrhyw ddata, gwaith, a chyfathrebiadau a drosglwyddir neu a dderbynnir gan y Defnyddiwr; ac</p>
						</li>
						<li>
						<p>Unrhyw ddefnydd annerbyniol o'r Gwasanaeth, gan gynnwys, heb gyfyngiad, unrhyw ddatganiad, data, neu gynnwys a wneir, a drosglwyddir, neu a ailgyhoeddir gan y Defnyddiwr a waherddir fel bod yn annerbyniol yn unol ag Adran 5 uchod.</p>
						</li>
						</ol>
						<li>
						<p>Amrywiol.</p>
						</li>
						<ol class="c">
						<li>
						<p>Aseiniad. Ni fydd y Defnyddiwr yn gallu aseinio unrhyw un o'i hawliau neu rwymedigaethau o dan y Cytundeb hwn, boed hynny trwy weithrediad y gyfraith neu fel arall, heb ganiatâd ysgrifenedig PPL ymlaen llaw. Bydd unrhyw ymgais gan Ddefnyddiwr i aseinio ei hawliau neu rwymedigaethau o dan y Cytundeb hwn sy'n torri'r Adran 10(A) hon yn ddi-rym a heb unrhyw effaith.</p>
						</li>
						<li>
						<p>Cyfraith Llywodraethu. Bydd y Cytundeb hwn a'r berthynas gyfreithiol rhwng y Partïon yn cael eu llywodraethu a'u hadeiladu yn unol â chyfreithiau Cymanwlad Massachusetts, heb ystyried ei hegwyddorion gwrthdaro cyfreithiau.</p>
						</li>
						<li>
						<p>Adeiladu. Mae'r Partïon yn cytuno na chaiff y Cytundeb hwn ei ddehongli o blaid nac yn erbyn y naill Barti na'r llall oherwydd awduraeth. Mae penawdau adrannau'r Cytundeb hwn wedi'u cynnwys er hwylustod yn unig ac ni fyddant yn effeithio ar ddehongliad y Cytundeb hwn.</p>
						</li>
						<li>
						<p>Dim Hepgoriad. Ni fydd yr hepgoriad gan y naill Barti neu'r llall o dorri neu darfu ar unrhyw ddarpariaeth yn y Cytundeb hwn yn gweithredu fel, neu'n cael ei ddehongli i fod, yn hepgoriad o unrhyw doriad dilynol o'r Cytundeb hwn.</p>
						</li>
						<li>
						<p>Difrifoldeb. Ni fydd annilysrwydd neu anorfodadwyedd unrhyw ddarpariaeth a gynhwysir yma yn effeithio ar ddilysrwydd unrhyw ddarpariaethau eraill yn y Cytundeb hwn, a dehonglir y Cytundeb hwn ym mhob modd fel pe bai darpariaeth annilys neu anorfodadwy o'r fath wedi'i hepgor, neu, i'r graddau a ganiateir gan gyfraith berthnasol, bydd paragraff annilys neu anorfodadwy o'r fath yn cael ei ddisodli gan baragraff arall mor debyg o ran telerau ag y bo modd ac a allai fod yn gyfreithiol, yn ddilys ac yn orfodadwy.</p>
						</li>
						<li>
						<p>Force Majeure. Ni fydd PPL yn gyfrifol am oedi neu fethiannau mewn perfformiad sy'n deillio o weithredoedd Duw, gweithredoedd awdurdod sifil neu filwrol, terfysgaeth, tân, llifogydd, streiciau, rhyfel, epidemigau, pandemigau, prinder ynni, neu weithredoedd neu achosion eraill sy'n rhesymol y tu hwnt i reolaeth PPL. Mae PPL yn cytuno i roi rhybudd i’r Defnyddiwr yn brydlon ar ôl i ddigwyddiad force majeure ddigwydd, ac i ddefnyddio ymdrechion diwyd i ailddechrau perfformiad mor brydlon ag sy'n ymarferol yn fasnachol.</p>
						</li>
						</ol>
						</ol></div></p>`)

						$('[data-qst-id="1496ac4b-135b-4ddb-8505-ccd51cbe920e"]').html(`<p><div class=""><style>
						ol.a {list-style-type: decimal; padding-left:15px;}
						ol.b {list-style-type: lower-roman; padding-left:45px;}
						ol.c {list-style-type: upper-alpha; padding-left:30px;}
						</style>
						<ol class="a">
						<ol class="c" start="7">
						<li>
						<p>Contractwyr Annibynnol. Mae PPL, y Defnyddiwr, a'u personél priodol, yn a byddant yn gontractwyr annibynnol ac ni fydd gan unrhyw un yn rhinwedd y Cytundeb hwn unrhyw hawl, pŵer nac awdurdod i weithredu neu greu unrhyw rwymedigaeth, datganedig neu oblygedig, ar ran unrhyw un o'r lleill.</p>
						</li>
						<li>
						<p>Diwygiad. Bydd gan PPL yr hawl, ar unrhyw adeg a heb rybudd, i ychwanegu neu addasu telerau'r Cytundeb trwy bostio ychwanegiadau neu addasiadau o'r fath i'r Gwasanaeth. Ystyrir bod mynediad y Defnyddiwr i'r Gwasanaeth neu ei ddefnydd o'r Gwasanaeth ar ôl y dyddiad y bydd telerau diwygiedig o'r fath yn dod i rym yn gyfystyr â derbyn telerau o'r fath.</p>
						</li>
						</ol>
						</ol>
						<p>&nbsp;</p>
						<p>Trwy dderbyn isod, rydych yn cydnabod ac yn derbyn Telerau Gwasanaeth PPL ar gyfer cyrchu'r Gwasanaeth.</p>
						<p>&nbsp;</p>
						<p>Diwygiwyd ddiwethaf: 04.01.2021</p></div></p>`)

						cym('Search', 'Chwilio')
						cym('Add', 'Ychwanegu')
						cym('Yes', 'Ydy')
						cym('No', 'Nac ydy')
						cym('Mailing Address', 'Cyfeiriad Postio')
						cym('Search for...', 'Chwilio am...')
						cym('Search', 'Chwilio')
						cym('Add', 'Ychwanegu')
					}
					
				}

				

				setTimeout(function() {
					accessibilityCore()	
					directProviderRegistrationTranslation()
					checkContrastFile()

					// Forms error summary
					if ('.form-validation-summary') {
						setTimeout(() => {
							$('.form-validation-summary').attr('tabindex', '0').focus();
						}, 500);		
					}
		
					$('button').on('click',function(){
						waitForElem(`.form-validation-summary`).then(() => {
							setTimeout(() => {
								$('.form-validation-summary').attr('tabindex', '0').focus();
							}, 500);
						})

						waitForElem(`.form-result-container`).then(() => {
							directProviderRegistrationTranslation()

							inputTranslation()
						})
					})


				}, 500); 
				
					
				$('body').click(function() {
					accessibilityCore()
					checkContrastFile()
					
					// directProviderRegistrationTranslation()
				})  

				waitForElem(`.form-body`).then(() => {
					const mutationObserver = new MutationObserver(entries => {
						inputTranslation()
						checkContrastFile()
						directProviderRegistrationTranslation()

					});
					
					const parent = document.querySelector('.form-main__questions-container');
					
					if (localStorage.getItem('language') === 'cym') {
						mutationObserver.observe(parent, {
							subtree: true,
							attributes: true,
							childList: true,
						});
					}
				})

				
			
			} 
		
    
        },

        bindUIActions: function () {
            // Bind actions

			$('body').on('click', '.js-cym-btn', function(){
				localStorage.setItem('language', 'cym');
				location.reload();
			});

			$('body').on('click', '.js-eng-btn', function(){
				localStorage.setItem('language', 'eng');
				location.reload();
			});

			if ( localStorage.getItem('language') === 'cym') { 
				$("html").attr("lang", 'cy')
				$('.js-eng-btn').attr("lang", 'en')
				$('.js-cym-btn').removeAttr("lang")
			} else if ( localStorage.getItem('language') === 'eng'){
				$("html").attr("lang", 'en')
				$('.js-cym-btn').attr("lang", 'cy')
				$('.js-eng-btn').removeAttr("lang")
			}

		
			const skipContentBtn = document.querySelector('.js-skip-content');
			if (skipContentBtn !== null ) {
				skipContentBtn.addEventListener('click', (e)=> {
					e.preventDefault();
					$([document.documentElement, document.body]).animate({
						scrollTop: $('.cms-content').offset().top
					}, 500);
	
					$('#myNavbar a, #myNavbar li').attr('tabindex' , '2');
					$('.cms-content a, .cms-content input, .cms-content select, .cms-content textarea, .js-skip-content, .footer-container a').attr('tabindex' , '1');
				});
			}


			$('body').on('click', '.js-accessibility-widget__font-size', function(e) {
				e.preventDefault();
				var fontSize = $(this).data('font-size');
				localStorage.setItem('font', fontSize === '16px' ? 'normal' : 'big');
				changeHtmlFontSize(fontSize);
				location.reload();
			});

			$('body').on('click', '.js-accessibility-widget__contrast', function(e) {
				e.preventDefault();
				var contrastMode = $(this).data('contrast');
				localStorage.setItem('contrast', contrastMode);
				if (contrastMode === 'normal') {
					$('body').removeClass('contrast');
				} else if (contrastMode === 'dark') {
					$('body').addClass('contrast');
				}
				checkContrastFile()
				checkContrastFileSignUp()
			});

			
			

        },

        unload: function () {},
    };

    Attributes.init();
})();